/*===========================
		COMMON css
===========================*/

@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
  scroll-behavior: smooth;
}

body {
  font-family: $font !important;
  font-weight: normal;
  font-style: normal;
  color: $gray;
  overflow-x: hidden;
  background: #f1f5f9;
}

* {
  margin: 0;
  padding: 0;
  @include box-sizing(border-box);
}

a:focus,
input:focus,
textarea:focus,
button:focus,
.btn:focus,
.btn.focus,
.btn:not(:disabled):not(.disabled).active,
.btn:not(:disabled):not(.disabled):active {
  text-decoration: none;
  outline: none;
  @include box-shadow(none);
}

a:hover {
  color: $primary;
}
button,
a {
  @include transition(0.3s);
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

i,
span,
a {
  display: inline-block;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

ul,
ol {
  margin: 0px;
  padding: 0px;
  list-style-type: none;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 25px;
  margin: 0px;
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.para-width-500 {
  max-width: 500px;
  width: 100%;
}

.container {
  @media #{$xs} {
    padding: 0 30px;
  }
}

/* ========== cart style ========== */
.card-style {
  background: $white;
  box-sizing: border-box;
  padding: 25px 30px;
  position: relative;
  box-shadow: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);
  border-radius: 7px;

  @media #{$xs} {
    padding: 20px;
  }

  .jvm-zoom-btn {
    position: absolute;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border: 1px solid $black-10;
    right: 30px;
    bottom: 30px;
    cursor: pointer;

    &.jvm-zoomin {
      bottom: 70px;
    }
  }

  .dropdown-toggle {
    border: none;
    background: none;

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.07));

    li {
      &:hover {
        a {
          color: $primary !important;
        }
      }

      a {
        display: block;
        font-size: 14px;
      }
    }
  }
}

.error-box {
  max-width: 330px;
  margin: 65px auto;

  @media #{$xs} {
    margin: 25px auto;

    img {
      max-width: 180px;
    }

    h1 {
      font-size: 28px;
    }
  }

  a {
    border-radius: 7px;
    padding: 7px 25px;
  }
}

.max-w-350 {
  max-width: 350px;
}

.more-btn-wrapper {
  .dropdown-menu {
    border: 0.5px solid #efefef;
    box-shadow: 0px 5px 10px rgba(26, 25, 85, 0.08);
    border-radius: 5px;

    .dropdown-item {
      &:active,
      &:focus {
        background: none;
      }

      a {
        width: 100%;
        border-radius: 4px;
        color: $dark;
      }
      i {
        font-weight: 700;
      }
    }
  }
}

/* ======= Border Radius ========= */
.radius-4 {
  border-radius: 4px;
}
.radius-10 {
  border-radius: 10px;
}
.radius-30 {
  border-radius: 30px;
}
.radius-50 {
  border-radius: 50px;
}
.radius-full {
  border-radius: 50%;
}

// scroll-top
.scroll-top {
  width: 45px;
  height: 45px;
  background: $primary;
  display: none;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: $white;
  border-radius: 5px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  z-index: 9;
  cursor: pointer;
  @include transition(0.3s);

  &:hover {
    color: $white;
    background: rgba($primary, 0.8);
  }
}

.form-control {
  &:focus {
    box-shadow: none;
  }
}

.form-control.is-valid:focus,
.was-validated .form-control:valid:focus,
.form-control.is-invalid:focus,
.was-validated .form-control:invalid:focus,
.form-check-input.is-valid:focus,
.was-validated .form-check-input:valid:focus,
.form-check-input.is-invalid:focus,
.was-validated .form-check-input:invalid:focus,
.form-check-input:focus,
.radio-style.radio-success .form-check-input:focus,
.radio-style.radio-warning .form-check-input:focus,
.radio-style.radio-danger .form-check-input:focus {
  box-shadow: none;
}

.hover-underline:hover {
  text-decoration: underline;
}

/* ========= Theme Change Css ============= */
.darkTheme {
  color: rgba($white, 0.7);

  .text-gray {
    color: rgba($white, 0.5) !important;
  }

  .card-style {
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);

    .dropdown-menu {
      background: $dark-2;
    }
  }

  .more-btn-wrapper {
    .dropdown-menu {
      border-color: $dark-4;

      .dropdown-item {
        a {
          color: $white;
        }
      }
    }
  }
}
