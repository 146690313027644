/* ============= Pricing css =============== */
.monthly-pricing-section {
  .section-title {
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 60px;
    text-align: center;

    h1 {
      width: 90%;
      margin-left: auto;
      margin-right: auto;

      @media #{$xs} {
        width: 100%;
      }
    }
  }
}

.single-pricing {
  background: $white;
  @include box-shadow(0px 10px 35px rgba(210, 210, 210, 0.17));
  border-radius: 5px;
  padding: 55px 30px;
  text-align: center;
  position: relative;

  @media #{$xs} {
    margin-bottom: 30px;
  }

  @media (min-width: 768px) and (max-width: 850px) {
    padding-left: 15px;
    padding-right: 15px;
  }

  &.active {
    @include transform(scale(1.05, 1));
    z-index: 2;
    padding-top: 125px;
    padding-bottom: 65px;

    @media #{$xs} {
      @include transform(scale(1));
      padding-top: 55px;
      padding-bottom: 55px;
    }

    .popular {
      position: absolute;
      left: 30px;
      top: 65px;
      padding: 5px 15px;
      border-radius: 30px;
      background: $primary;
      color: $white;
      font-size: 14px;
      font-weight: 500;

      @media #{$xs} {
        top: 15px;
      }
    }
  }

  .pricing-header {
    h5 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 15px;
    }
    h3 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 20px;

      @media #{$md} {
        font-size: 20px;
      }
    }
    .price {
      border-top: 1px solid rgba(93, 101, 123, 0.1);
      border-bottom: 1px solid rgba(93, 101, 123, 0.1);
      margin-bottom: 25px;

      h1 {
        padding: 20px 0px;

        span {
          color: $gray;
        }
      }
    }
  }

  .pricing-body {
    ul {
      margin-bottom: 15px;

      li {
        padding-bottom: 20px;
        font-size: 14px;
        color: $black-50;
      }
    }
  }
}

/* ======= Pricing Table ======= */

.pricing-table {
  text-align: center;
  thead {
    tr {
      th {
        border-right: 1px solid $light;
        min-width: 150px;

        h5 {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        h1 {
          color: $primary;

          span {
            font-size: 14px;
            color: $gray;
            font-weight: 400;
          }
        }
      }
    }
  }
  tbody {
    tr {
      td {
        vertical-align: middle;
        border-right: 1px solid $light;
        min-width: 150px;
        padding: 20px 10px;

        &:first-child {
          text-align: left;
        }
      }
    }
  }
}

/* ========= Theme Change Css ============= */
.darkTheme {
  .single-pricing {
    background: $dark-4;
    @include box-shadow(0px 10px 35px rgba($dark-2, 0.17));

    .pricing-body {
      ul {
        li {
          color: rgba($white, 0.5);
        }
      }
    }
  }

  .pricing-table {
    thead {
      tr {
        th {
          border-right-color: rgba($light, 0.2);
        }
      }
    }
    tbody {
      tr {
        td {
          border-right-color: rgba($light, 0.2);
          p {
            color: $white;

            i {
              color: $white;
            }
          }
        }
      }
    }
  }
}
