@import "variables";
@import "mixin";
@import "common";

@import "./typography/typography";

@import "./badges/badges";

@import "./breadcrumb/breadcrumb";

@import "./buttons/buttons";

@import "./accordion/accordion";

@import "./alerts/alerts";

@import "./cards/cards";

@import "./carousel/carousel";

@import "./tabs/tabs";

@import "./tables/tables";

@import "./pagination/pagination";

@import "./progressbar/progressbar";

@import "./list/list";

@import "./forms/form-elements";

@import "./forms/form-edit";

@import "./notification/notification";

@import "./header/header";

@import "./dashboards/dashboards";

// ======= auth css
@import "./auth/signin";
@import "./auth/signup";
@import "./auth/reset-password";

@import "./task/task";

@import "./settings/settings";

@import "./clients/clients";

@import "./profile/profile";

@import "./chat/chat";

@import "./pricing/pricing";

@import "./projects/projects";

@import "./invoice/invoice";

@import "./modals/modals";

@import "./icons/icons";

@import "./calendar/calendar";

@import "./kanban/kanban";

@import "./file-manager/file-manager";

@import "preloader";

@import "option";

@import "sidebar";

@import "layout/layout";

@import "default";

a {
  color: $primary;
}

.verify-wrapper input {
  font-size: 16px;
  font-family: inherit;
  color: $gray;
  max-width: calc(100% / 6);
}

.verify-wrapper input:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.verify-wrapper input:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.verify-wrapper input:focus {
  border: 1px solid $primary;
  caret-color: $primary
}
@media #{$xs} {
  .verify-wrapper input {
    font-size: 14px;
    // width: 54px !important;
    // height: 30px !important;
  }
  .d-xs-block {
    display: block !important;
  }
  .d-xs-none {
    display: none !important;
  }
  .signin-wrapper {
    min-height: 450px;
  }
  @for $i from 1 through 45 {
    .ml-xs-#{5 * $i} {
      margin-left: 5px * $i;
    }
    .mb-xs-#{5 * $i} {
      margin-bottom: 5px * $i;
    }
  }
  @for $i from 1 through 45 {
    .mt-xs-#{5 * $i} {
      margin-top: 5px * $i;
    }
  }
  @for $i from 1 through 45 {
    .ml-xs-d-#{5 * $i} {
      margin-left: -5px * $i !important;
    }
  }
  .margin-xs-auto {
    margin: auto;
  }
}

@media #{$md} {
  @for $i from 0 through 45 {
    .ml-md-#{5 * $i} {
      margin-left: 5px * $i;
    }
    .mb-md-#{5 * $i} {
      margin-bottom: 5px * $i;
    }
  }
  .d-md-flex {
    display: flex !important;
  }
}
@media (max-width: 991px) {
  .d-tab-block {
    display: block !important;
  }
}
.MuiCircularProgress-root {
  color: $gray !important;
}
.border-right {
  border-right: 1px solid $primary;
}
.border-bottom {
  border-bottom: 1px solid $gray !important;
}
.update-feed .item:last-child {
  border-bottom: none !important;
}
.profile-persona-description {
  width: 60%;
  text-align: left;
}
.w-60 {
  width: 60%;
}
.margin-0-auto {
  margin: 0 auto;
}
.not-found {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.bottom-position {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.dashboard-team-overview .table-responsive {
  overflow-x: hidden !important;
  -webkit-overflow-scrolling: hidden !important;
}
@media #{$xs} {
  .profile-persona-description {
    width: 100%;
  }
  .dashboard-team-overview td {
    min-width: 130px;
  }
  .dashboard-team-overview td:first-child {
    min-width: unset !important;
  }
  .text-xs-center {
    text-align: center;
  }
  .tab-style-1 nav button {
    padding-bottom: 5px;
    margin-bottom: 10px;
  }
  .w-xs-100 {
    width: 100%;
  }
  // .text-sm {
  //   font-size: 12px;
  // }
  // .table td p {
  //   font-size: 12px;
  // }
  .dashboard-team-overview .table-responsive {
    overflow-x: auto !important;
    -webkit-overflow-scrolling: auto !important;
  }
}
.alignt-items-end {
  align-items: end;
}
.width-fit-content {
  width: fit-content;
}

.no-focus-border .select-style-1 .select-position select:focus {
  border-color: #e5e5e5;
}
.or-line {
  margin: 20px auto;
  position: relative;
  span {
    position: absolute;
    top: -12px;
    padding: 0 20px;
    background-color: #fff;
    left: calc(50% - 20px);
  }
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}

.import-people .MuiOutlinedInput-root .MuiAutocomplete-input {
  width: 100% !important;
  display: block;
}

.import-people .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e5e5e5 !important;
}

.gsi-material-button1 {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-appearance: none;
  background-color: #131314;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 20px;
  border-radius: 20px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #e3e3e3;
  cursor: pointer;
  font-family: 'Roboto', arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition: background-color .218s, border-color .218s, box-shadow .218s;
  transition: background-color .218s, border-color .218s, box-shadow .218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
  border-color: #8e918f;
}

.gsi-material-button1 .gsi-material-button-icon {
  height: 20px;
  margin-right: 8px;
  min-width: 20px;
  width: 20px;
  margin-bottom: 4px;
}

.gsi-material-button1 .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button1 .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: 'Roboto', arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button1 .gsi-material-button-state {
  -webkit-transition: opacity .218s;
  transition: opacity .218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button1:disabled {
  cursor: default;
  background-color: #13131461;
  border-color: #8e918f1f;
}

.gsi-material-button1:disabled .gsi-material-button-state {
  background-color: #e3e3e31f;
}

.gsi-material-button1:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button1:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button1:not(:disabled):active .gsi-material-button-state, 
.gsi-material-button1:not(:disabled):focus .gsi-material-button-state {
  background-color: white;
  opacity: 12%;
}

.gsi-material-button1:not(:disabled):hover {
  -webkit-box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, .30), 0 1px 3px 1px rgba(60, 64, 67, .15);
}

.gsi-material-button1:not(:disabled):hover .gsi-material-button-state {
  background-color: white;
  opacity: 8%;
}
.line-select {
  margin-bottom: 5px !important;
  margin-left: 10px;
}
.line-select select,
.line-select .lenses-select__control {
  border-radius: 0 !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #e5e5e5 !important;
  padding: 5px !important;
  text-align: center;
  box-shadow: none !important;
  margin-bottom: 0;
}
.line-select .lenses-select__indicators {
  display: none !important;
}
.line-select .select-position::after {
  display: none !important;
}

.chat-list-wrapper .chat-list.active {
  background: rgba(74, 108, 247, 0.1);
}

.input-style-3 input:focus {
  border-color: #efefef;
}
.fit-content {
  width: fit-content;
  max-width: 320px;
  margin: auto;
}
.message-box:last-child {
  margin-bottom: 0 !important;
}
.text-sm-12 {
  font-size: 12px;
}
.text-sm-11 {
  font-size: 11px;
}
.primary-btn-outline.deactive-btn {
  border: none;
}
.modal-contact {
  position: fixed; /* line I changed */
  overflow:auto; /* line I added */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}
.nav-item-disabled {
  opacity: 0.4;
}
.nav-item-disabled:hover .icon {
  color: $gray !important;
}
.input-style-1 input:focus, .input-style-1 textarea:focus {
  border-color: #e5e5e5;
}
.input-style-1.was-invalied input, .input-style-1.was-invalied textarea {
  border-color: #ee5368;
}
.full-height-card {
  min-height: calc(100vh - 90px); /* Adjust 40px as needed */
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0px;
}
.justify-content-normal {
  justify-content: normal !important;
}
.plainadmin-datepicker {
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root {
      border-radius: 50px;
    }
    .Mui-focused {
      border-color: $primary !important;
    }
  }
}
.plainadmin-datepicker .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e5e5e5 !important;
}
.plainadmin-datepicker .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #e5e5e5 !important;
}

.interest-select {
  .MuiFormControl-root {
    width: 100%;
    .MuiInputBase-root {
      border-radius: 50px;
    }
    .Mui-focused {
      border-color: $primary !important;
    }
  }
}
.interest-select .Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #e5e5e5 !important;
}
.interest-select .MuiFormControl-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #e5e5e5 !important;
}
.interest-select .MuiFormLabel-root {
  padding-top: 0 !important;
}
.flex-1 {
  flex: 1 1 auto;
}
.w-100px {
  width: 100px;
}
.round-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  grid-gap: 10px;
  gap: 10px;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 5;
  transition: all 0.4s ease-in-out;
  border: 1px solid transparent;
  overflow: hidden;
  font-size: 12px;
}
.interest-option {
  padding: 10px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}
input::placeholder {
  font-weight: normal;
  opacity: 0.4;
  color: '#B6B6B6';
}
.plain-admin-textarea:focus::placeholder {
  color: transparent;
}
textarea:focus::placeholder {
  color: transparent;
}
.text-light-gray {
  color: #B6B6B6 !important;
}
.message-box-wrapper {
  justify-content: flex-start !important;
}
.public-survey .full-height-card {
  min-height: calc(100vh - 116px);
}
.basic-info input, .basic-info select {
  border-radius: 4px !important;
  padding: 10px 16px !important;
}

.basic-info .value-input input {
  border-radius: 4px !important;
  padding: 6px 16px !important;
}

.basic-info .plainadmin-datepicker .MuiFormControl-root .MuiInputBase-root {
  border-radius: 4px;
}
.basic-info .checkbox-style .form-check-input {
  padding: 0px !important;
}
.border-color-gray {
  border-color: #B9B9B9 !important;
}
.insight-mobile {
  width: 100%;
}
.insight-mobile .card-style {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #FECA30;
}
.insight-mobile .show-full .card-style {
  border: 1px solid #EE5368;
}
.insight-mobile .show-full .card-style .close-icon {
  display: block;
  position: absolute;
  top: 5px;
  right: 15px;
}
.close-icon {
  display: none;
}
.insight-mobile .title {
  text-align: center !important;
}
.insight-mobile .text-content {
  display: none;
  justify-content: flex-end;
  align-items: center;
}
.insight-mobile .show-full .header-insight {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  text-align: left !important;
}
.insight-mobile .show-item .header-insight span {
  text-align: center !important;
}
.insight-mobile .show-full .text-content {
  display: block;
  text-align: left !important;
}
.insight-mobile .show-item h5 {
  font-size: 11px;
  font-weight: 500;
  text-align: center !important;
}
.round-cursor-default .round-btn {
  cursor: default !important;
}
.select-style-1 .select-position select:focus {
  border-color: #e5e5e5;
}