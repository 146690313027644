/* ========== Breadcrumb css ========== */

/* breadcrumb base styles */
.base-breadcrumb {
  border-radius: 6px;
  border: 1px solid $light-3;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 17px 30px;

  li,
  a {
    display: flex;
    align-items: center;
    gap: 10px;

    i {
      line-height: 1;
    }
  }

  li {
    font-size: 14px;
    font-weight: 500;

    a,
    span {
      display: flex;
      color: $gray;
    }

    &.active {
      color: $primary;
    }
  }
}

.breadcrumb-two {
  gap: 12px;

  li {
    gap: 12px;

    span {
      max-width: 4px;
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: $gray;
    }
  }

  a {
    gap: 5px;
  }
}

.breadcrumb-three {
  a {
    gap: 5px;
  }
}