/* ============ Calendar Css ============= */

.calendar-card {
  .fc {
    height: 450px;

    &#calendar-full {
      height: 600px;
    }

    table {
      border: none;
    }

    .fc-toolbar-title {
      font-size: 16px;
      font-weight: 500;
    }

    .fc-button {
      background: transparent;
      border: none;
      color: $gray;
      text-transform: capitalize;

      &:focus {
        @include box-shadow(none);
        color: $primary;
      }
    }

    th {
      text-align: left;
      border-bottom: 1px solid $black-10 !important;
      border-right: 0px;

      a {
        color: $gray;
        font-weight: 400;
      }
    }

    .fc-day {
      border-width: 4px;
      background: $white;

      &.fc-day-today {
        .fc-daygrid-day-frame {
          background: rgba($primary, 1);

          a {
            color: $white;
          }
        }
      }

      .fc-daygrid-day-frame {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        background: #f9f9f9;
        border-radius: 6px;
        // padding: 5px;

        a {
          color: $gray;
        }
      }
    }
  }

  .fc-theme-standard td,
  .fc-theme-standard th {
    border-color: transparent;
  }
}

/* ========= Theme Change Css ============ */
.darkTheme {
  .calendar-card {
    .fc {
      .fc-button {
        color: $white;
      }

      th {
        border-bottom: 1px solid $black-10;

        a {
          color: rgba($white, 0.7);
          font-weight: 400;
        }
      }

      .fc-day {
        padding: 5px;
        background: $dark-4;

        &.fc-day-today {
          .fc-daygrid-day-frame {
            background: $primary;

            a {
              color: $white;
            }
          }
        }

        .fc-daygrid-day-frame {
          background: $dark-2;

          a {
            color: $light;
          }
        }
      }
    }
  }
}
