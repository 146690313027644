// Font Family
$font: "Plus Jakarta Sans", sans-serif;

// ======== white colors variants
$white: #fff;
$white-90: rgba($white, 0.9);
$white-80: rgba($white, 0.8);
$white-70: rgba($white, 0.7);
$white-60: rgba($white, 0.6);
$white-50: rgba($white, 0.5);
$white-40: rgba($white, 0.4);
$white-30: rgba($white, 0.3);
$white-20: rgba($white, 0.2);
$white-10: rgba($white, 0.1);

// ========== black colors variants
$black: #000;
$black-90: rgba($black, 0.9);
$black-80: rgba($black, 0.8);
$black-70: rgba($black, 0.7);
$black-60: rgba($black, 0.6);
$black-50: rgba($black, 0.5);
$black-40: rgba($black, 0.4);
$black-30: rgba($black, 0.3);
$black-20: rgba($black, 0.2);
$black-10: rgba($black, 0.1);

// ======== dark color
$dark: #1A2142;
$dark-2: #2f3546;
$dark-3: #090e34;
$dark-4: #262d3f;

// ======== gray color
$gray: #5d657b;
$gray-2: #c2cbd6;
$gray-3: #8F9AAD;

// ======== Sidebar color
$sidebar-text: #9AA4CA;

// ======== primary color
// $primary: #e2555f;
$primary: #EE5368;
// ======== secondary color
$secondary: #00c1f8;

// ======== success color
$success: #219653;
$success-2: #10B981;
$success-light: #42d29d;

// ======== danger color
$danger: #d50100;
$danger-2: #FB5454;
$danger-light: #fa5a78;

// ======== warning color
$warning: #f7c800;

// ======== info color
$info: #97ca31;

// ======== purple color
$purple: #9b51e0;

// ======== light color
$light: #efefef;
$light-2: #e5e5e5;
$light-3: #DFE5EF;

// ======== active color
$active: #365CF5;

// ======== deactive color
$deactive: #E9E9E9;

// ======== orange color
$orange: #f2994a;

// ======== deep-blue color
$deep-blue: #345d9d;
$blue-light: #49BEFF;

// ========
$doc: #f98db4;

// ========
$photo: #93cfe2;

// ======== Shadow
$shadow-one: 0px 5px 20px rgba(0, 0, 0, 0.1);
$shadow-three: 0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);

// ======== Responsive Variables
$desktop: "only screen and (min-width: 1400px)";
$laptop: "only screen and (min-width: 1200px) and (max-width: 1399px)";
$lg: "only screen and (min-width: 992px) and (max-width: 1199px)";
$md: "only screen and (min-width: 768px) and (max-width: 991px)";
$xs: "(max-width: 767px)";
$sm: "only screen and (min-width: 550px) and (max-width: 767px)";
