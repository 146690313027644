/* =========== tables css =========== */

.table {
  border-collapse: inherit;
  border-spacing: 0px;

  & > :not(caption) > * > * {
    padding: 15px 0;
    border-bottom-color: $light;
    vertical-align: middle;
  }
  & > :not(:last-child) > :last-child > * {
    border-bottom-color: $light;
  }
  tbody {
    tr {
      &:first-child > * {
        padding-top: 20px;
      }
      &:last-child > * {
        border-bottom-color: transparent;
        padding-bottom: 0px;
      }
    }
  }

  th {
    h6 {
      font-weight: 500;
      color: $dark;
      font-size: 14px;
    }
  }

  td {
    &.min-width {
      padding: 5px;

      @media #{$xs} {
        min-width: 150px;
      }
    }

    p {
      font-size: 14px;
      line-height: 1.5;
      color: $gray;

      a {
        color: inherit;

        &:hover {
          color: $primary;
        }
      }
    }
  }

  .lead-info {
    min-width: 200px;
  }
  .lead-email {
    min-width: 150px;
    white-space: nowrap;
  }
  .lead-phone {
    min-width: 160px;
  }
  .lead-company {
    min-width: 180px;
  }

  .referrals-image {
    min-width: 150px;

    .image {
      width: 55px;
      max-width: 100%;
      height: 55px;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }
  }

  .lead {
    display: flex;
    align-items: center;

    .lead-image {
      max-width: 50px;
      width: 100%;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 15px;

      img {
        width: 100%;
      }
    }
    .lead-text {
      width: 100%;
    }
  }
  .employee-image {
    width: 50px;
    max-width: 100%;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 15px;

    img {
      width: 100%;
    }
  }

  .action {
    display: flex;
    align-items: center;

    button {
      border: none;
      background: transparent;
      padding: 0px 6px;
      font-size: 18px;

      &.edit {
        &:hover {
          color: $primary;
        }
      }

      &::after {
        display: none;
      }
    }

    .dropdown-menu {
      @include box-shadow(0px 0px 5px rgba(0, 0, 0, 0.07));

      li {
        &:hover {
          a {
            color: $primary !important;
          }
        }
        a {
          display: block;
        }
      }
    }
  }
}

// ===== Top selling table
.top-selling-table {
  tr {
    th,
    td {
      vertical-align: middle;
      padding: 10px 5px;
    }

    .min-width {
      min-width: 80px;
      white-space: nowrap;
    }
  }

  .form-check-input[type="checkbox"] {
    margin-left: 5px;

    &:checked {
      background-color: $primary;
      border-color: $primary;
    }
  }
  .product {
    display: flex;
    align-items: center;
    min-width: 150px;

    .image {
      border-radius: 4px;
      overflow: hidden;
      margin-right: 15px;
      max-width: 50px;
      width: 100%;
      height: 50px;

      img {
        width: 100%;
      }
    }
    p {
      width: 100%;
    }
  }
}

// ===== referrals-table
.referrals-table-card {
  .title {
    @media #{$xs} {
      .right {
        width: 100%;
      }
    }
    @media #{$sm} {
      .right {
        width: auto;
      }
    }
  }
  .referrals-table {
    td {
      padding: 10px;
    }
  }
}

/* ===== lead-table ===== */
.lead-table {
  th,
  td {
    padding: 10px 5px;
  }

  .name {
    min-width: 120px;
  }

  .email {
    min-width: 130px;
  }
  .project {
    min-width: 150px;
  }

  .status {
    min-width: 120px;
    text-align: center;
  }
  .action {
    min-width: 60px;
  }
}

// ======== Clients Table
.clients-table-card {
  .table {
    .employee-info {
      min-width: 150px;
    }
  }
}
.clients-table {
  th,
  td {
    padding: 5px;

    &.min-width {
      min-width: 150px;
    }
  }

  .employee-image {
    margin-right: 0px;
  }
}

.table-pagination {
  ul {
    li {
      a {
        width: 32px;
        height: 32px;
        background: $light-2;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 14px;
        color: $black-70;

        &:hover,
        &.active {
          background: $primary;
          color: $white;
        }
      }
    }
  }
}

.table-search {
  form {
    max-width: 270px;
    position: relative;

    input {
      width: 100%;
      border: 1px solid $light;
      background: rgba($light, 0.5);
      border-radius: 4px;
      height: 46px;
      padding-left: 44px;
      @include transition(0.3s);

      &:focus {
        border-color: $primary;
      }
    }
    button {
      position: absolute;
      border: none;
      background: transparent;
      left: 16px;
      top: 0;
      height: 46px;
      color: $gray;
    }
  }
}

.striped-table {
  td,
  th {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.dataTable-pagination li:hover a,
.dataTable-pagination .active a,
.dataTable-pagination .active a:focus,
.dataTable-pagination .active a:hover {
  background: $primary;
  color: $white;
  border-radius: 3px;
}
.dataTable-pagination .ellipsis:hover a {
  background: transparent;
  color: #333;
}

.dataTable-selector {
  border-radius: 5px;
  border: 1px solid $black-10;
}

.dataTable-input {
  width: 100%;
  border: 1px solid $light;
  background: rgba($light, 0.5);
  border-radius: 4px;
  height: 46px;
  @include transition(0.3s);

  &:focus {
    border-color: $primary;
  }
}

.dataTable-input:focus {
  border-color: $primary;
}

.dataTable-table > tbody > tr > td,
.dataTable-table > tbody > tr > th,
.dataTable-table > tfoot > tr > td,
.dataTable-table > tfoot > tr > th,
.dataTable-table > thead > tr > td,
.dataTable-table > thead > tr > th {
  padding: 15px 10px;
}

.dataTable-top > div:last-child {
  &.dataTable-search {
    @media #{$xs} {
      margin-top: 12px;
      float: left;
    }
  }

}
@media #{$xs} {
  .dataTable-pagination ul {
      li {
        margin: 5px 0;
      }
  }
}




.activity-card {
  .button-group {
    display: flex;
    align-items: center;
    border-radius: 50px;
    border: 1px solid #efefef;

    a {
      font-weight: 500;
      font-size: 12px;
      padding: 4px 16px;
      display: inline-block;
      color: $dark-2;
      border-radius: 50px;
      margin: 3px;

      &.active,
      &:hover {
        background: $primary;
        color: $white;
      }
    }
  }
}

.activity-table {
  td {
    min-width: 90px;
    padding: 10px;
    border: none;

    &.time {
      min-width: 110px;
    }
  }

  .icon {
    width: 24px;
    height: 24px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .status {
      width: 8px;
      height: 8px;
      border: 1px solid $white;
      border-radius: 50%;
      position: absolute;
      top: -1px;
      right: -1px;
    }
  }
  .status-btn {
    font-size: 12px;
    border-radius: 50px;
    border: 1px solid #efefef;
    padding: 4px 14px;
    color: $gray;
    min-width: 90px;
    text-align: center;
  }
}

.sell-order-table {
  th,
  td {
    min-width: 60px;
    padding: 10px 5px;
    border: none;
  }
}

/* ========== Theme Change Css ============= */

.darkTheme {
  .table {
    & > :not(caption) > * > * {
      border-bottom-color: rgba($light, 0.2);
    }
    & > :not(:last-child) > :last-child > * {
      border-bottom-color: rgba($light, 0.2);
    }

    th {
      h6 {
        color: $white;
      }
    }

    td {
      p {
        color: rgba($white, 0.7);
      }
    }

    .action {
      button {
        color: $white-70;

        &.edit {
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }

  .table-search {
    form {
      input {
        border-color: $dark;
        background: rgba($dark-2, 0.5);
        color: $light;
      }
    }
  }

  .table-pagination {
    ul {
      li {
        a {
          background: rgba($dark-2, 0.5);
          color: $light;

          &:hover,
          &.active {
            background: $primary;
            color: $white;
          }
        }
      }
    }
  }

  .activity-card {
    .button-group {
      a {
        color: rgba($light, 0.5);

        &.active,
        &:hover {
          color: $white;
        }
      }
    }
  }

  .dataTable-table th a {
    &::after {
      border-bottom-color: $white-70;
    }
    &::before {
      border-top-color: $white-70;
    }
  }

  .dataTable-wrapper.no-footer .dataTable-container {
    border-bottom-color: $white-10;
  }

  .dataTable-selector {
    background: transparent;
    color: $white-70;
    border-color: rgba($light, 0.2);

    &:focus {
      outline: none;
    }
  }

  .dataTable-input {
    background: $dark-2;
    color: $white-70;

    &:focus {
      background: $dark;
    }
  }

  .dataTable-pagination li a {
    color: $white-50;
  }
  .dataTable-pagination li:hover a,
  .dataTable-pagination .active a,
  .dataTable-pagination .active a:focus,
  .dataTable-pagination .active a:hover {
    background: $primary;
    color: $white;
  }
}
