 /* =========== Sidebar css =========== */
.sidebar-nav-wrapper {
  background: $white;
  width: 250px;
  padding: 20px 0px;
  height: 100vh;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  top: 0;
  left: 0;
  z-index: 999;
  box-shadow: 0px 0px 30px 0px rgba(200, 208, 216, 0.30);
  @include transition(0.3s);
  @include transform(translateX(0));

  @media #{$lg, $md, $xs} {
    @include transform(translateX(-260px));
  }

  &.active {
    @include transform(translateX(-260px));

    @media #{$lg, $md, $xs} {
      @include transform(translateX(0px));
    }
  }

  .navbar-logo {
    padding: 0 25px;
    margin-bottom: 30px;
    margin-top: 11px;
  }

  .sidebar-nav {
    .divider {
      padding: 5px 25px;
      width: 100%;

      hr {
        height: 1px;
        background: #E9EEF1;
      }
    }

    ul {
      &.collapsing {
        transition: none;
      }

      .nav-item {
        position: relative;

        &.nav-item-has-children {
          & > a {
            color: $dark;

            &:not(.collapsed){
              .icon {
                color: $primary;
              }
            } 

            &::after {
              content: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.34306 7.76387L9.02356 3.8071C9.32923 3.47851 9.11273 2.91666 8.68048 2.91666L1.31947 2.91666C0.887218 2.91666 0.670743 3.47851 0.976393 3.8071L4.6569 7.76387C4.8464 7.96758 5.15356 7.96758 5.34306 7.76387Z' fill='%239AA4CA'/%3E%3C/svg%3E%0A");
              position: absolute;
              right: 25px;
              top: 11px;
              font-size: 12px;
              @include transition(0.3s);
              @include transform(rotate(180deg));
            }

            &.collapsed {
              color: $sidebar-text;

              &::before {
                opacity: 0;
                visibility: hidden;
              }

              &::after {
                @include transform(rotate(0deg));
              }
            }
          }

          ul {
            padding: 10px 24px 40px 57px;
            li {
              margin-bottom: 12px;

              &:last-child {
                margin-bottom: 0px;
              }

              a {
                font-size: 14px;
                font-weight: 500;
                padding: 0;
                display: flex;
                align-items: center;

                &.active,
                &:hover {
                  color: $dark;
                }

                i {
                  font-size: 16px;
                  margin-right: 15px;
                }

                span.text {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                }

                span.pro-badge {
                  background: $primary;
                  color: $white;
                  padding: 2.5px 7px;
                  border-radius: 30px;
                  font-size: 10px;
                  line-height: 13px;
                  font-weight: 600;
                  margin-left: 10px;
                }
              }
            }
          }
        }

        a {
          display: flex;
          align-items: center;
          color: $sidebar-text;
          font-size: 14px;
          font-weight: 500;
          width: 100%;
          position: relative;
          z-index: 1;
          padding: 7px 25px;

          span.text {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            @include transition(0.3s);
          }

          span.pro-badge {
            background: $primary;
            color: $white;
            padding: 2.5px 7px;
            border-radius: 30px;
            font-size: 10px;
            line-height: 13px;
            font-weight: 600;
            margin-left: 10px;
          }

          .icon {
            margin-right: 12px;
            margin-top: -4px;
            font-size: 18px;
            @include transition(0.3s);

            svg {
              fill: currentColor;
              @include transition(0.3s);
            }
          }
        }
        &.active,
        &:hover {
          & > a,
          & > a.collapsed {
            color: $dark;

            .icon {
              color: $primary;
            }
          }
        }
      }
    }
  }
}

.sidebar-nav-wrapper {
  &.style-2 {
    .sidebar-nav {
      ul {
        .nav-item {
          &.nav-item-has-children {
            ul {
              li {
                a {
                  &.active,
                  &:hover {
                    color: $white;
                    border-color: $primary;
                    background: $primary;

                    span.pro-badge {
                      background: $white;
                      color: $primary;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($black, 0.3);
  z-index: 11;
  @include transform(translateX(-100%));
  opacity: 0;
  visibility: hidden;

  @media #{$desktop, $laptop} {
    display: none;
  }

  &.active {
    @media #{$lg, $md, $xs} {
      opacity: 1;
      visibility: visible;
      @include transform(translateX(0));
    }
  }
}

.main-wrapper {
  @include transition(0.3s);
  margin-left: 250px;
  min-height: 100vh;
  padding-bottom: 85px;
  position: relative;

  @media #{$lg, $md, $xs} {
    margin-left: 0;
  }

  @media #{$xs} {
    padding-bottom: 110px;
  }

  &.active {
    margin-left: 0;
  }

  .container-fluid {
    padding-left: 40px;
    padding-right: 40px;

    @media #{$xs} {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .footer {
    padding: 25px 0;
    justify-items: flex-end;
    position: absolute;
    bottom: 0;
    width: 100%;

    .copyright {
      p {
        a {
          color: inherit;

          &:hover {
            color: $primary;
          }
        }
      }
    }

    .terms {
      @media #{$xs} {
        margin-bottom: 10px;
        text-align: center;
      }

      a {
        color: $gray;

        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.promo-box {
  background: #F5F8FC;
  padding: 15px 18px;
  text-align: center;
  max-width: 210px;
  margin: 0 auto;
  margin-top: 200px;
  border-radius: 14px;
  
  .promo-icon {
    max-width: 82px;
    width: 100%;
    height: 82px;
    border-radius: 50%;
    margin: -58px auto 12px;
    border: 5px solid $white;
  }

  h3 {
    font-size: 14px;
    line-height: 22px;
    font-weight: 600;
    margin-bottom: 7px;
  }
  p {
    font-size: 10px;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 15px;
  }

  .main-btn {
    border-radius: 43px;
    padding: 7px 12px;
    width: 100%;
    justify-content: center;
  }
}

/* ====== Theme Change CSS ====== */
.darkTheme {
  .sidebar-nav-wrapper {
    background: $dark-4;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);

    .sidebar-nav {
      ul {
        .nav-item {
          &.nav-item-has-children {
            & > a {
              color: $white;

              &.collapsed {
                color: $gray-2;
              }
            }

            ul {
              li {
                a {
                  &.active,
                  &:hover {
                    color: $white;
                  }
                }
              }
            }
          }

          a {
            color: $gray-2;
          }
          &.active,
          &:hover {
            & > a,
            & > a.collapsed {
              color: $white;
            }
          }
        }
      }
    }

    &.style-2 {
      background: $dark-3;
      .sidebar-nav {
        ul {
          .nav-item {
            &.nav-item-has-children {
              & > a {
                color: $white;

                &.collapsed {
                  color: $gray-2;
                }
              }

              ul {
                li {
                  a {
                    &.active,
                    &:hover {
                      color: $white;
                      border-color: $primary;
                      background: $primary;
                    }
                  }
                }
              }
            }

            a {
              color: $gray-2;
            }
            &.active,
            &:hover {
              & > a,
              & > a.collapsed {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  .promo-box {
    background: #262a38;

    .promo-icon {
      border: 5px solid $dark-4;
    }
  }
}
