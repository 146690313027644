/* ============== Icons Css ===========*/
.icons-wrapper {
  .icons,
  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    & > div,
    li {
      display: flex;
      align-items: center;
      margin: 10px;
      flex-basis: 215px;

      @media (max-width: 400px) {
        flex-basis: 100%;
      }

      i {
        max-width: 45px;
        width: 100%;
        height: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #efefef;
        border-radius: 4px;
        background: transparent;
        color: $dark;
        font-size: 20px;
        margin-right: 10px;
      }
      span {
        color: $dark;
        user-select: all;
      }
    }
  }
}

/* ========= Theme Change Css =========== */
.darkTheme {
  .icons-wrapper {
    .icons,
    ul {
      & > div,
      li {
        i {
          color: $white;
          border-color: $gray;
        }
        span {
          color: rgba($white, 0.5);
        }
      }
    }
  }
}
