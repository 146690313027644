/* =========== Kanban Css ============= */
.invisible {
  display: none;
}

.kanban-header {
  background: $white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  border: 1px solid $black-10;
  border-radius: 10px;
  margin-bottom: 40px;

  @media #{$xs} {
    display: block;
  }

  @media #{$sm} {
    display: flex;
  }

  .kanban-header-members {
    display: flex;
    align-items: center;

    @media #{$xs} {
      margin-bottom: 20px;
    }

    @media #{$sm} {
      margin-bottom: 0px;
    }

    .members-image {
      padding-left: 20px;

      img {
        width: 38px;
        height: 38px;
        border: 2px solid $white;
        border-radius: 50%;
        overflow: hidden;
        margin-left: -20px;
      }
    }

    .add-member-button {
      background: transparent;
      border: none;
      font-size: 14px;
      padding-left: 12px;

      i {
        font-size: 12px;
        font-weight: 700;
        padding-right: 4px;
      }
    }
  }

  .add-task-button {
    button {
      i {
        font-weight: 700;
        padding-right: 4px;
        font-size: 14px;
      }
    }
  }
}

.kanban-card-wrapper {
  .card-wrapper-title {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 25px;
    text-transform: uppercase;
  }
}
.kanban-card {
  background: $white;
  border-radius: 6px;
  border-bottom: 3px solid;
  border-bottom-color: $primary;
  box-shadow: 0px 1px 4px rgba(26, 25, 85, 0.08);
  margin-bottom: 24px;

  .kanban-button {
    padding: 25px 22px;
    text-align: left;
    width: 100%;
  }

  .kanban-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  .priority {
    padding: 4px 8px;
    border-radius: 5px;
    font-size: 12px;
    font-weight: 500;
    background: rgba($primary, 0.1);
    color: $primary;
  }

  .tooltip-icon {
    width: 25px;
    height: 25px;
    border-radius: 5px;
    background-color: #eff2f6;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 14px;
      font-weight: 700;
    }
  }

  h4 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 12px;
  }

  .kanban-card-footer {
    margin-bottom: 25px;
    padding: 0px 22px;

    .more-btn {
      &::after {
        display: none;
      }
    }
  }

  &.high {
    border-bottom-color: $danger-light;

    .priority {
      background: rgba($danger-light, 0.1);
      color: $danger-light;
    }
  }

  &.low {
    border-bottom-color: $success-light;

    .priority {
      background: rgba($success-light, 0.1);
      color: $success-light;
    }
  }

  &.medium {
    border-bottom-color: $purple;

    .priority {
      background: rgba($purple, 0.1);
      color: $purple;
    }
  }
}

.task-details-modal,
.add-task-modal {
  .modal-title {
    font-size: 24px;
    font-weight: 600;

    @media #{$xs} {
      font-size: 20px;
    }
  }

  .close-button {
    position: absolute;
    right: 30px;
    top: 20px;
    color: $gray;

    &:hover {
      color: $danger-light;
    }
  }
  .modal-dialog {
    max-width: 1075px;

    @media #{$md, $xs} {
      max-width: 95%;
    }

    .modal-content {
      box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);
      border-radius: 8px;
    }
  }

  .input-style-1 {
    input,
    textarea {
      padding-top: 11px;
      padding-bottom: 11px;
      border-radius: 6px;
    }
  }
  .select-style-1 {
    .select-position select {
      padding-top: 11px;
      padding-bottom: 11px;
      border-radius: 6px;
    }
  }

  .task-modal-left {
    padding: 40px;
    position: relative;
    border-right: 1px solid $black-10;

    @media #{$md} {
      padding-bottom: 0px;
    }

    @media #{$xs} {
      padding: 25px;
      padding-bottom: 0px;
    }

    @media #{$sm} {
      padding: 40px;
      padding-bottom: 0px;
    }

    h5 {
      font-weight: 600;
      font-size: 24px;
      margin-bottom: 40px;
    }
  }

  .task-modal-right {
    padding: 40px;
    padding-top: 108px;

    @media #{$md} {
      padding-top: 0px;
    }

    @media #{$xs} {
      padding: 25px;
      padding-top: 0px;
    }

    @media #{$sm} {
      padding: 40px;
      padding-top: 0px;
    }

    .btn {
      height: 42px;
      align-items: center;
    }
  }
}

.task-details-modal {
  .modal-dialog {
    max-width: 820px;

    @media #{$md, $xs} {
      max-width: 95%;
    }
  }

  .modal-body {
    padding: 40px;
    padding-bottom: 20px;

    @media #{$xs} {
      padding: 25px;
      padding-bottom: 5px;
    }

    @media #{$sm} {
      padding: 40px;
      padding-bottom: 20px;
    }
  }

  .priority {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 500;
    border-radius: 5px;
    margin-left: 15px;
  }

  .desc {
    font-size: 18px;
    margin-bottom: 18px;
  }

  .modal-meta-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -25px;

    .modal-meta {
      padding: 0 25px;
      border-right: 1px solid $light;
      margin-bottom: 20px;

      &:last-child {
        border: none;
      }
    }
  }
}

/* ========= Theme Change Css =========== */
.darkTheme {
  .kanban-header {
    background: $dark-4;
    border-color: $dark-2;

    .kanban-header-members {
      .members-image {
        img {
          border-color: $dark-2;
        }
      }
    }
  }
  .kanban-card {
    background: $dark-4;

    .tooltip-icon {
      background-color: $dark-2;
    }
  }

  .add-task-modal {
    .modal-content {
      background: $dark-4;
    }
  }
}
