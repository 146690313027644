/* ========== Accordion css ========== */

/* accordion base styles */
.base-accordion {
  box-shadow: 0px 10px 14px -4px rgba(145, 158, 171, 0.10), 0px 0px 2px 0px rgba(145, 158, 171, 0.20);

  .accordion-item {
    background: transparent;
    border-color: $light-3;
  }
  
  .accordion-button {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    padding: 20px 35px;

    &:focus {
      border: none;
      box-shadow: none;
    }

    &:not(.collapsed) {
      color: inherit;
      box-shadow: none;
      background-color: initial;
    }

    &:after {
      background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4297 5.35156C13.4297 5.49219 13.3828 5.63281 13.2656 5.72656L7.875 11.0234C7.66406 11.2344 7.33594 11.2344 7.125 11.0234L1.73437 5.72656C1.52344 5.51563 1.52344 5.1875 1.73437 4.97656C1.94531 4.76562 2.27344 4.76562 2.48437 4.97656L7.5 9.89844L12.5156 4.95312C12.7266 4.74219 13.0547 4.74219 13.2656 4.95312C13.3594 5.09375 13.4297 5.21094 13.4297 5.35156Z' fill='currentColor'/%3E%3C/svg%3E%0A");
    }
  }

  .accordion-body {
    padding: 22px 35px 25px;
    border-top: 1px solid $light-3;
    font-size: 14px;
    font-weight: 500;
    color: $gray;
  }

}

.accordion-two {
  padding: 17px;
  border: 1px solid $light-3;
  border-radius: 6px;

  .accordion-item {
    border: none;

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .accordion-button {
    border: none;
    border-radius: 6px;
    padding: 14px 18px;
    background: #F5F8FC;

    &:not(.collapsed) {
      color: $white;
      background-color: $primary;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.4297 5.35156C13.4297 5.49219 13.3828 5.63281 13.2656 5.72656L7.875 11.0234C7.66406 11.2344 7.33594 11.2344 7.125 11.0234L1.73437 5.72656C1.52344 5.51563 1.52344 5.1875 1.73437 4.97656C1.94531 4.76562 2.27344 4.76562 2.48437 4.97656L7.5 9.89844L12.5156 4.95312C12.7266 4.74219 13.0547 4.74219 13.2656 4.95312C13.3594 5.09375 13.4297 5.21094 13.4297 5.35156Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }
  }

  .accordion-body {
    border: none;
    padding: 20px 18px 10px;
  }
}

/* ============ Theme Change Css ============== */
.darkTheme {
  .base-accordion {
    .accordion-item {
      background: transparent;
      border-color: $dark-2;
    }

    .accordion-body {
      border-color: $dark-2;
    }

    .accordion-button {
      color: $white;
      background: transparent;

      &:after {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.1158 5.82187C16.1158 5.99062 16.0596 6.15937 15.9189 6.27187L9.45019 12.6281C9.19707 12.8812 8.80332 12.8812 8.5502 12.6281L2.08145 6.27187C1.82832 6.01874 1.82832 5.62499 2.08144 5.37187C2.33457 5.11874 2.72832 5.11874 2.98145 5.37187L9.00019 11.2781L15.0189 5.34374C15.2721 5.09062 15.6658 5.09062 15.9189 5.34374C16.0314 5.51249 16.1158 5.65312 16.1158 5.82187Z' fill='white'/%3E%3C/svg%3E%0A");
      }
    }

  }

  .accordion-two {
    border-color: $dark-2;

    .accordion-button {
      background-color: $dark-2;

      &:not(.collapsed) {
        background-color: $primary;
      }
    }
   }
}