@charset "UTF-8";
/* ============ signup css ============= */
.auth-row {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.auth-cover-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px;
  position: relative;
  z-index: 1;
  height: 100%;
}
@media (max-width: 767px) {
  .auth-cover-wrapper {
    padding: 30px 20px;
  }
}
.auth-cover-wrapper .auth-cover .title {
  text-align: cover;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .auth-cover-wrapper .auth-cover .title h1 {
    font-size: 24px;
  }
}
.auth-cover-wrapper .auth-cover .cover-image {
  max-width: 100%;
  margin: auto;
}
.auth-cover-wrapper .auth-cover .cover-image img {
  width: 100%;
}
.auth-cover-wrapper .auth-cover .shape-image {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 5%;
}

.signup-wrapper {
  background: #fff;
  padding: 60px;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .signup-wrapper {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .signup-wrapper {
    padding: 30px;
  }
}
.signup-wrapper .form-wrapper {
  width: 100%;
}
.signup-wrapper .singup-option button {
  font-size: 16px;
  font-weight: 600;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .signup-wrapper .singup-option button {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .signup-wrapper .singup-option button {
    padding-left: 30px;
    padding-right: 30px;
  }
}
@media (max-width: 767px) {
  .signup-wrapper .singup-option button {
    width: 100%;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .signup-wrapper .singup-option button {
    width: auto;
  }
}
.signup-wrapper .singup-option a:hover {
  text-decoration: underline;
}

/* ============ Theme Change Css ============== */
.darkTheme .auth-row {
  background: #262d3f;
}
.darkTheme .signup-wrapper {
  background: #262d3f;
}
.darkTheme .signup-wrapper .danger-btn-outline {
  background: transparent;
  color: rgba(213, 1, 0, 0.8);
  border-color: rgba(213, 1, 0, 0.8);
}
.darkTheme .signup-wrapper .danger-btn-outline:hover {
  color: #fff;
  background: #d50100;
}

/* ============ reset-password css ============= */
.auth-row {
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}

.auth-cover-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 45px;
  position: relative;
  z-index: 1;
  height: 100%;
}
@media (max-width: 767px) {
  .auth-cover-wrapper {
    padding: 30px 20px;
  }
}
.auth-cover-wrapper .auth-cover .title {
  text-align: cover;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .auth-cover-wrapper .auth-cover .title h1 {
    font-size: 24px;
  }
}
.auth-cover-wrapper .auth-cover .cover-image {
  max-width: 100%;
  margin: auto;
}
.auth-cover-wrapper .auth-cover .cover-image img {
  width: 100%;
}
.auth-cover-wrapper .auth-cover .shape-image {
  position: absolute;
  z-index: -1;
  right: 0;
  bottom: 5%;
}

.reset-password-wrapper {
  background: #fff;
  padding: 60px;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .reset-password-wrapper {
    padding: 40px;
  }
}
@media (max-width: 767px) {
  .reset-password-wrapper {
    padding: 30px;
  }
}
.reset-password-wrapper .form-wrapper {
  width: 100%;
}

/* ============ Theme Change Css ============== */
.darkTheme .auth-row {
  background: #262d3f;
}
.darkTheme .reset-password-wrapper {
  background: #262d3f;
}

/* ============ task css ============= */
.task-table.task-completed {
  text-decoration: line-through;
}
.task-table .table tr {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.task-table .table tr:hover {
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
}
.task-table .table tr:last-child > * {
  padding-bottom: 15px;
}
.task-table .table td {
  padding: 10px;
}
.task-table .table td.min-width {
  min-width: 150px;
}
.task-table .table td.name-title {
  min-width: 200px;
}
.task-table .table td:first-child > * {
  padding-left: 30px;
}
.task-table .table td:last-child > * {
  padding-right: 30px;
}
.task-table .table td .action .delete-btn:hover {
  color: #d50100 !important;
}
.task-table .table td .action .link-btn:hover {
  color: #4a6cf7 !important;
}
.task-table .table td .action .more-btn:hover {
  color: #4a6cf7;
}

/* =========== settings css ============== */
.settings-card-1 .profile-info .profile-image {
  max-width: 75px;
  width: 100%;
  height: 75px;
  border-radius: 50%;
  margin-right: 20px;
  position: relative;
  z-index: 1;
}
.settings-card-1 .profile-info .profile-image img {
  width: 100%;
  border-radius: 50%;
}
.settings-card-1 .profile-info .profile-image .update-image {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: #efefef;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 99;
}
.settings-card-1 .profile-info .profile-image .update-image:hover {
  opacity: 0.9;
}
.settings-card-1 .profile-info .profile-image .update-image input {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
  z-index: 99;
}
.settings-card-1 .profile-info .profile-image .update-image label {
  cursor: pointer;
  z-index: 99;
}

/* ============== clients css ============ */
.client-profile-wrapper {
  background: #fff;
  border-radius: 4px;
}
.client-profile-wrapper .client-cover {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  position: relative;
  height: 160px;
}
.client-profile-wrapper .client-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.client-profile-wrapper .client-cover .update-image {
  position: absolute;
  right: 30px;
  bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  padding: 1px 15px;
  display: inline-block;
  width: auto;
  color: #262d3f;
  z-index: 9999;
  cursor: pointer;
}
.client-profile-wrapper .client-cover .update-image:hover {
  opacity: 0.9;
}
@media (max-width: 767px) {
  .client-profile-wrapper .client-cover .update-image {
    right: 50%;
    bottom: 50%;
    -webkit-transform: translate(50%, 50%);
    -moz-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    -o-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    white-space: nowrap;
  }
}
.client-profile-wrapper .client-cover .update-image input {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99999;
  cursor: pointer;
}
.client-profile-wrapper .client-cover .update-image label {
  z-index: 99;
  cursor: pointer;
}
.client-profile-wrapper .client-cover .update-image label i {
  margin-right: 10px;
}
.client-profile-wrapper .client-profile-photo {
  position: relative;
  margin-top: -80px;
}
@media (max-width: 767px) {
  .client-profile-wrapper .client-profile-photo {
    margin-top: -50px;
  }
}
.client-profile-wrapper .client-profile-photo .image {
  max-width: 170px;
  width: 100%;
  height: 170px;
  border-radius: 50%;
  position: relative;
  margin: 0 auto;
  border: 6px solid #fff;
}
@media (max-width: 767px) {
  .client-profile-wrapper .client-profile-photo .image {
    max-width: 120px;
    height: 120px;
  }
}
.client-profile-wrapper .client-profile-photo .image img {
  width: 100%;
  border-radius: 50%;
}
.client-profile-wrapper .client-profile-photo .image .update-image {
  position: absolute;
  right: 0px;
  bottom: 0px;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #efefef;
  color: #262d3f;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  cursor: pointer;
}
.client-profile-wrapper .client-profile-photo .image .update-image:hover {
  opacity: 0.9;
}
.client-profile-wrapper .client-profile-photo .image .update-image input {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  cursor: pointer;
}
.client-profile-wrapper .client-profile-photo .image .update-image label {
  z-index: 99;
  cursor: pointer;
}
.client-profile-wrapper .client-info {
  padding: 30px;
}
.client-profile-wrapper .client-info .socials {
  display: flex;
  align-items: center;
}
.client-profile-wrapper .client-info .socials li a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(239, 239, 239, 0.5);
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  color: #5d657b;
  font-size: 16px;
  margin-right: 20px;
}
.client-profile-wrapper .client-info .socials li a:hover {
  color: #fff;
  background: #4a6cf7;
  border-color: #4a6cf7;
}

.clients-table-card .dropdown-toggle {
  border: none;
  background: none;
}
.clients-table-card .dropdown-toggle::after {
  display: none;
}

/* ============ Theme Change Css =============== */
.darkTheme .client-profile-wrapper {
  background: #262d3f;
}
.darkTheme .client-profile-wrapper .client-info .socials li a {
  background: rgba(38, 45, 63, 0.5);
  border: 1px solid #2f3546;
  color: #fff;
}
.darkTheme .client-profile-wrapper .client-info .socials li a:hover {
  color: #fff;
  background: #4a6cf7;
  border-color: #4a6cf7;
}

/* ============ profile css ============= */
.profile-wrapper {
  background: #fff;
  border-radius: 4px;
}
.profile-wrapper .profile-cover {
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  position: relative;
  height: 160px;
}
.profile-wrapper .profile-cover img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.profile-wrapper .profile-cover .update-image {
  position: absolute;
  right: 30px;
  bottom: 20px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 30px;
  padding: 1px 15px;
  display: inline-block;
  width: auto;
  color: #262d3f;
}
@media (max-width: 767px) {
  .profile-wrapper .profile-cover .update-image {
    right: 50%;
    bottom: 50%;
    -webkit-transform: translate(50%, 50%);
    -moz-transform: translate(50%, 50%);
    -ms-transform: translate(50%, 50%);
    -o-transform: translate(50%, 50%);
    transform: translate(50%, 50%);
    white-space: nowrap;
  }
}
.profile-wrapper .profile-cover .update-image input {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.profile-wrapper .profile-cover .update-image label i {
  margin-right: 10px;
}
.profile-wrapper .profile-photo {
  position: relative;
  margin-top: -80px;
  margin-left: 40px;
  margin-right: 40px;
  max-width: 240px;
  width: 100%;
}
@media (max-width: 767px) {
  .profile-wrapper .profile-photo {
    margin-top: -50px;
    margin-left: 20px;
    margin-right: 20px;
  }
}
.profile-wrapper .profile-photo .image {
  max-width: 170px;
  width: 100%;
  height: 170px;
  border-radius: 50%;
  position: relative;
  border: 6px solid #fff;
}
@media (max-width: 767px) {
  .profile-wrapper .profile-photo .image {
    max-width: 120px;
    height: 120px;
  }
}
.profile-wrapper .profile-photo .image img {
  width: 100%;
  border-radius: 50%;
}
.profile-wrapper .profile-photo .image .update-image {
  position: absolute;
  right: 0px;
  bottom: 0px;
  border: 2px solid #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background: #efefef;
  color: #262d3f;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-wrapper .profile-photo .image .update-image input {
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
}
.profile-wrapper .profiles-activities {
  padding-right: 40px;
}
@media (max-width: 767px) {
  .profile-wrapper .profiles-activities {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.profile-wrapper .profiles-activities .more-btn-wrapper button {
  border: none;
  background: none;
}
@media (max-width: 767px) {
  .profile-wrapper .profiles-activities .more-btn-wrapper button {
    display: none;
  }
}
.profile-wrapper .profiles-activities .more-btn-wrapper button::after {
  display: none;
}
.profile-wrapper .profile-info {
  margin: 30px;
  padding: 30px 0px;
  border-top: 1px solid #efefef;
}
@media (max-width: 767px) {
  .profile-wrapper .profile-info {
    margin-left: 20px;
    margin-right: 20px;
  }
}
.profile-wrapper .profile-info p a:hover {
  color: #4a6cf7 !important;
}
.profile-wrapper .profile-info .socials {
  display: flex;
  align-items: center;
}
.profile-wrapper .profile-info .socials li a {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(239, 239, 239, 0.5);
  border: 1px solid #e5e5e5;
  border-radius: 50%;
  color: #5d657b;
  font-size: 16px;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .profile-wrapper .profile-info .socials li a {
    margin-right: 10px;
  }
}
.profile-wrapper .profile-info .socials li a:hover {
  color: #fff;
  background: #4a6cf7;
  border-color: #4a6cf7;
}

/* ========== Theme Change Css ============= */
.darkTheme .more-btn-wrapper .dropdown-menu {
  background-color: #2f3546;
}
.darkTheme .more-btn-wrapper .dropdown-menu .dropdown-item:hover {
  background-color: #262d3f;
}
.darkTheme .profile-wrapper {
  background: #262d3f;
}
.darkTheme .profile-wrapper .profiles-activities ul li p {
  color: #fff;
}
.darkTheme .profile-wrapper .profile-info {
  border-color: #2f3546;
}
.darkTheme .profile-wrapper .profile-info .socials li a {
  background: rgba(38, 45, 63, 0.5);
  border: 1px solid #2f3546;
  color: #fff;
}
.darkTheme .profile-wrapper .profile-info .socials li a:hover {
  color: #fff;
  background: #4a6cf7;
  border-color: #4a6cf7;
}
.darkTheme .profile-wrapper .socials li a {
  background: rgba(38, 45, 63, 0.5);
  border: 1px solid #2f3546;
  color: #fff;
}
.darkTheme .profile-wrapper .socials li a:hover {
  color: #fff;
  background: #4a6cf7;
  border-color: #4a6cf7;
}

/* ============ chat css =========== */
.chat-list-card {
  padding: 30px 0;
}
.chat-list-card > .title {
  padding: 0 30px;
}
.chat-list-card form {
  padding: 0 30px;
}
@media (max-width: 767px) {
  .chat-list-card form {
    padding: 0px 20px;
  }
}

.chat-list-wrapper .chat-list {
  padding: 0 30px;
}
@media (max-width: 767px) {
  .chat-list-wrapper .chat-list {
    padding: 0px 20px;
  }
}
.chat-list-wrapper .chat-list:hover {
  background: rgba(74, 108, 247, 0.1);
}
.chat-list-wrapper .chat-list:last-child {
  border-bottom: 0px;
}
.chat-list-wrapper .chat-list:last-child .chat-list-item {
  border-bottom: 0px solid #efefef;
}

.chat-list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #efefef;
  padding: 20px 0;
}
.chat-list-item .image {
  max-width: 35px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
}
.chat-list-item .image img {
  width: 100%;
  border-radius: 50%;
}
.chat-list-item .image .status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1.5px solid #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background: #5d657b;
}
.chat-list-item .image .status.active {
  background: #4a6cf7;
}
.chat-list-item p {
  color: #5d657b;
  line-height: 1.4;
}
.chat-list-item .content {
  width: 100%;
}
.chat-list-item .content .title {
  display: flex;
  justify-content: space-between;
}
.chat-list-item .content .title span {
  font-size: 14px;
  color: #5d657b;
}
.chat-list-item .content button {
  border: none;
  background: transparent;
  margin-left: 15px;
  font-size: 14px;
}

/* === === === Message box css === === === */
.message-box-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.message-box {
  display: flex;
  margin-bottom: 20px;
}
.message-box .image {
  max-width: 35px;
  width: 100%;
  height: 35px;
  border-radius: 50%;
  margin-right: 20px;
}
@media (max-width: 767px) {
  .message-box .image {
    margin-right: 10px;
  }
}
.message-box .image img {
  width: 100%;
  border-radius: 50%;
}
.message-box .content-box {
  border: 1px solid #efefef;
  padding: 12px 30px;
  border-radius: 4px;
  max-width: 550px;
  width: 100%;
}
@media (max-width: 767px) {
  .message-box .content-box {
    padding: 12px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .message-box .content-box {
    padding: 12px 18px;
  }
}
.message-box .content-box .content p {
  font-size: 14px;
  line-height: 22px;
}
.message-box.right {
  flex-direction: row-reverse;
}
.message-box.right .image {
  margin-right: 0px;
  margin-left: 20px;
}
@media (max-width: 767px) {
  .message-box.right .image {
    margin-left: 10px;
  }
}

/* ============ Message Form ============ */
.message-form form {
  display: flex;
}
.message-form form .message-field {
  width: 100%;
  position: relative;
}
.message-form form .message-field .file-emoji {
  position: absolute;
  right: 15px;
  top: 0;
  display: flex;
}
.message-form form .message-field .file-emoji .file-upload {
  width: 40px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
}
.message-form form .message-field .file-emoji .file-upload input {
  opacity: 0;
}
.message-form form .message-field .file-emoji .file-upload span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -1;
}
.message-form form .message-field .file-emoji button {
  border: 1px solid transparent;
  background: transparent;
  width: 40px;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.message-form form button.send-btn {
  max-width: 56px;
  width: 100%;
  height: 56px;
  background: #4a6cf7;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 4px;
  border: 1px solid transparent;
  font-size: 18px;
  margin-left: 10px;
}
@media (max-width: 767px) {
  .message-form form button.send-btn {
    display: none;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .message-form form button.send-btn {
    display: flex;
  }
}

/* ============= Chat about css ============= */
.chat-about .chat-about-profile {
  padding: 20px 0;
  border-bottom: 1px solid #efefef;
}
.chat-about .chat-about-profile .image {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  margin-bottom: 20px;
}
.chat-about .chat-about-profile .image img {
  width: 100%;
}
.chat-about .activity-meta {
  padding-top: 30px;
  padding-bottom: 10px;
  border-bottom: 1px solid #efefef;
}
.chat-about .activity-meta ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  margin-bottom: 12px;
}
.chat-about .profile-info {
  padding-top: 20px;
}
.chat-about .profile-info ul li {
  font-size: 14px;
  margin-bottom: 12px;
}

.doughnutChart-legend ul {
  display: flex;
  flex-wrap: wrap;
}
.doughnutChart-legend ul li {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 50%;
  font-size: 14px;
}
.doughnutChart-legend ul li.orange .left::before {
  background: #f2994a;
}
.doughnutChart-legend ul li.danger .left::before {
  background: #d50100;
}
.doughnutChart-legend ul li.warning .left::before {
  background: #f7c800;
}
.doughnutChart-legend ul li .left {
  position: relative;
  padding-left: 20px;
}
.doughnutChart-legend ul li .left::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #4a6cf7;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}
.doughnutChart-legend ul li .right {
  text-align: left;
  padding: 0 10px;
}

/* ========== Theme Change Css ============= */
.darkTheme .chat-list-item {
  border-bottom-color: rgba(239, 239, 239, 0.2);
}
.darkTheme .message-box .content-box {
  border-color: rgba(239, 239, 239, 0.2);
}
.darkTheme .message-form form .message-field .file-emoji button {
  color: #fff;
}
.darkTheme .message-form form .message-field .file-emoji .file-upload span {
  color: #fff;
}
.darkTheme .chat-about .chat-about-profile {
  border-bottom-color: rgba(239, 239, 239, 0.2);
}
.darkTheme .chat-about .chat-about-profile .content h5 {
  color: #fff;
}
.darkTheme .chat-about .activity-meta {
  border-bottom-color: rgba(239, 239, 239, 0.2);
}

/* ============= Pricing css =============== */
.monthly-pricing-section .section-title {
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 60px;
  text-align: center;
}
.monthly-pricing-section .section-title h1 {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767px) {
  .monthly-pricing-section .section-title h1 {
    width: 100%;
  }
}

.single-pricing {
  background: #fff;
  -webkit-box-shadow: 0px 10px 35px rgba(210, 210, 210, 0.17);
  -moz-box-shadow: 0px 10px 35px rgba(210, 210, 210, 0.17);
  box-shadow: 0px 10px 35px rgba(210, 210, 210, 0.17);
  border-radius: 5px;
  padding: 55px 30px;
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .single-pricing {
    margin-bottom: 30px;
  }
}
@media (min-width: 768px) and (max-width: 850px) {
  .single-pricing {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.single-pricing.active {
  -webkit-transform: scale(1.05, 1);
  -moz-transform: scale(1.05, 1);
  -ms-transform: scale(1.05, 1);
  -o-transform: scale(1.05, 1);
  transform: scale(1.05, 1);
  z-index: 2;
  padding-top: 125px;
  padding-bottom: 65px;
}
@media (max-width: 767px) {
  .single-pricing.active {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    padding-top: 55px;
    padding-bottom: 55px;
  }
}
.single-pricing.active .popular {
  position: absolute;
  left: 30px;
  top: 65px;
  padding: 5px 15px;
  border-radius: 30px;
  background: #4a6cf7;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
}
@media (max-width: 767px) {
  .single-pricing.active .popular {
    top: 15px;
  }
}
.single-pricing .pricing-header h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.single-pricing .pricing-header h3 {
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 20px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-pricing .pricing-header h3 {
    font-size: 20px;
  }
}
.single-pricing .pricing-header .price {
  border-top: 1px solid rgba(93, 101, 123, 0.1);
  border-bottom: 1px solid rgba(93, 101, 123, 0.1);
  margin-bottom: 25px;
}
.single-pricing .pricing-header .price h1 {
  padding: 20px 0px;
}
.single-pricing .pricing-header .price h1 span {
  color: #5d657b;
}
.single-pricing .pricing-body ul {
  margin-bottom: 15px;
}
.single-pricing .pricing-body ul li {
  padding-bottom: 20px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

/* ======= Pricing Table ======= */
.pricing-table {
  text-align: center;
}
.pricing-table thead tr th {
  border-right: 1px solid #efefef;
  min-width: 150px;
}
.pricing-table thead tr th h5 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 15px;
}
.pricing-table thead tr th h1 {
  color: #4a6cf7;
}
.pricing-table thead tr th h1 span {
  font-size: 14px;
  color: #5d657b;
  font-weight: 400;
}
.pricing-table tbody tr td {
  vertical-align: middle;
  border-right: 1px solid #efefef;
  min-width: 150px;
  padding: 20px 10px;
}
.pricing-table tbody tr td:first-child {
  text-align: left;
}

/* ========= Theme Change Css ============= */
.darkTheme .single-pricing {
  background: #262d3f;
  -webkit-box-shadow: 0px 10px 35px rgba(47, 53, 70, 0.17);
  -moz-box-shadow: 0px 10px 35px rgba(47, 53, 70, 0.17);
  box-shadow: 0px 10px 35px rgba(47, 53, 70, 0.17);
}
.darkTheme .single-pricing .pricing-body ul li {
  color: rgba(255, 255, 255, 0.5);
}
.darkTheme .pricing-table thead tr th {
  border-right-color: rgba(239, 239, 239, 0.2);
}
.darkTheme .pricing-table tbody tr td {
  border-right-color: rgba(239, 239, 239, 0.2);
}
.darkTheme .pricing-table tbody tr td p {
  color: #fff;
}
.darkTheme .pricing-table tbody tr td p i {
  color: #fff;
}

/* ============ Projects Css =========== */
.project-card .card-body {
  padding: 15px 0px;
}
.project-card .card-body .chart-wrapper {
  display: flex;
  align-items: center;
}
.project-card .card-body .chart-wrapper .chart {
  max-width: 55px;
  width: 100%;
  height: 55px;
  position: relative;
  margin-bottom: 25px;
  margin-right: 20px;
}
.project-card .card-body .chart-wrapper .chart span {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.project-card .card-body .chart-wrapper .ldBar-chart {
  position: relative;
}
.project-card .card-body .chart-wrapper .ldBar-chart .baseline {
  stroke: inherit;
  stroke-width: 8px;
  opacity: 0.1;
}
.project-card .card-body .chart-wrapper .ldBar-chart .mainline {
  stroke: inherit;
  stroke-width: 8px;
}
.project-card .card-body .chart-wrapper .ldBar-chart .ldBar-label {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}
.project-card .card-body .chart-wrapper .chart-body {
  width: 250px;
  margin: auto;
}
.project-card .card-body .chart-wrapper .chart-body .ldBar-label {
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  flex-direction: column;
}
.project-card .card-body .chart-wrapper .chart-body .ldBar-label::after {
  content: "Task completed";
  font-size: 14px;
  display: block;
  width: 100%;
  font-weight: 500;
  color: #5d657b;
}
.project-card .card-footer .project-image {
  display: flex;
  align-items: center;
  margin-top: 15px;
}
.project-card .card-footer .project-image ul {
  display: flex;
  align-items: center;
}
.project-card .card-footer .project-image ul li {
  width: 28px;
  height: 28px;
  border: 2px solid #fff;
  overflow: hidden;
  margin-left: -10px;
  border-radius: 50%;
}
.project-card .card-footer .project-image ul li:first-child {
  margin-left: 0px;
}
.project-card .card-footer .project-image ul li img {
  width: 100%;
}
.project-card .card-footer .project-image a {
  margin-left: 10px;
  color: #5d657b;
  font-size: 14px;
}

.activity-wrapper ul li {
  display: flex;
  padding-bottom: 30px;
  position: relative;
  z-index: 1;
}
.activity-wrapper ul li:last-child {
  padding-bottom: 0px;
}
.activity-wrapper ul li::after {
  content: "";
  width: 2px;
  height: 100%;
  border-left: 1px dashed rgba(0, 0, 0, 0.1);
  position: absolute;
  left: 25px;
  top: 0;
  z-index: -1;
}
@media (max-width: 375px) {
  .activity-wrapper ul li {
    flex-direction: column;
  }
  .activity-wrapper ul li::after {
    display: none;
  }
  .activity-wrapper ul li .icon {
    margin-bottom: 10px;
  }
  .activity-wrapper ul li .content .title {
    flex-wrap: wrap;
  }
}
.activity-wrapper ul li.primary .icon {
  border-color: rgba(74, 108, 247, 0.1);
}
.activity-wrapper ul li.primary .icon i {
  background: rgba(74, 108, 247, 0.1);
  color: #4a6cf7;
}
.activity-wrapper ul li.danger .icon {
  border-color: rgba(213, 1, 0, 0.1);
}
.activity-wrapper ul li.danger .icon i {
  background: rgba(213, 1, 0, 0.1);
  color: #d50100;
}
.activity-wrapper ul li.success .icon {
  border-color: rgba(33, 150, 83, 0.1);
}
.activity-wrapper ul li.success .icon i {
  background: rgba(33, 150, 83, 0.1);
  color: #219653;
}
.activity-wrapper ul li.orange .icon {
  border-color: rgba(242, 153, 74, 0.1);
}
.activity-wrapper ul li.orange .icon i {
  background: rgba(242, 153, 74, 0.1);
  color: #f2994a;
}
.activity-wrapper ul li .icon {
  max-width: 50px;
  width: 100%;
  height: 50px;
  position: relative;
  border-radius: 50%;
  border: 2px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background: #fff;
  font-size: 20px;
}
.activity-wrapper ul li .icon i {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.activity-wrapper ul li .content p a {
  color: #5d657b;
}

.stroke-primary {
  stroke: #4a6cf7;
}

.stroke-success {
  stroke: #219653;
}

.stroke-orange {
  stroke: #f2994a;
}

.stroke-warning {
  stroke: #f7c800;
}

.stroke-info {
  stroke: #97ca31;
}

.stroke-danger {
  stroke: #d50100;
}

.darkTheme .activity-wrapper ul li::after {
  border-color: rgba(255, 255, 255, 0.1);
}

/* =========== Invoice Css ============= */
.invoice-card .invoice-header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
@media (max-width: 767px) {
  .invoice-card .invoice-header {
    flex-direction: column;
  }
}
.invoice-card .invoice-header .invoice-logo {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  overflow: hidden;
}
@media (max-width: 767px) {
  .invoice-card .invoice-header .invoice-logo {
    order: -1;
    margin-bottom: 30px;
  }
}
.invoice-card .invoice-header .invoice-logo img {
  width: 100%;
}
@media (max-width: 767px) {
  .invoice-card .invoice-header .invoice-date {
    margin-top: 30px;
  }
}
.invoice-card .invoice-header .invoice-date p {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 10px;
}
.invoice-card .invoice-header .invoice-date p span {
  font-weight: 500;
}
.invoice-card .invoice-address {
  padding-top: 30px;
  display: flex;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .invoice-card .invoice-address {
    display: block;
  }
}
.invoice-card .invoice-address .address-item {
  margin-right: 30px;
  min-width: 250px;
}
.invoice-card .invoice-address .address-item h5 {
  margin-bottom: 15px;
}
.invoice-card .invoice-address .address-item h1 {
  margin-bottom: 10px;
  font-size: 24px;
}
.invoice-card .invoice-address .address-item p {
  margin-bottom: 10px;
}
@media (max-width: 767px) {
  .invoice-card .invoice-action ul li {
    flex: 1;
  }
}
@media (max-width: 767px) {
  .invoice-card .invoice-action ul li a {
    width: 100%;
  }
}

.invoice-table th,
.invoice-table td {
  padding: 10px 8px;
}
.invoice-table .service {
  min-width: 150px;
}
.invoice-table .desc {
  min-width: 150px;
}
.invoice-table .qty {
  min-width: 150px;
}
.invoice-table .amount {
  min-width: 100px;
}

/* ========== Modals Css ============ */
.warning-modal .warning-card .icon {
  font-size: 120px;
  line-height: 1;
}

/* ============== Icons Css ===========*/
.icons-wrapper .icons,
.icons-wrapper ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}
.icons-wrapper .icons > div,
.icons-wrapper .icons li,
.icons-wrapper ul > div,
.icons-wrapper ul li {
  display: flex;
  align-items: center;
  margin: 10px;
  flex-basis: 215px;
}
@media (max-width: 400px) {
  .icons-wrapper .icons > div,
.icons-wrapper .icons li,
.icons-wrapper ul > div,
.icons-wrapper ul li {
    flex-basis: 100%;
  }
}
.icons-wrapper .icons > div i,
.icons-wrapper .icons li i,
.icons-wrapper ul > div i,
.icons-wrapper ul li i {
  max-width: 45px;
  width: 100%;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #efefef;
  border-radius: 4px;
  background: transparent;
  color: #262d3f;
  font-size: 20px;
  margin-right: 10px;
}
.icons-wrapper .icons > div span,
.icons-wrapper .icons li span,
.icons-wrapper ul > div span,
.icons-wrapper ul li span {
  color: #262d3f;
  user-select: all;
}

/* ========= Theme Change Css =========== */
.darkTheme .icons-wrapper .icons > div i,
.darkTheme .icons-wrapper .icons li i,
.darkTheme .icons-wrapper ul > div i,
.darkTheme .icons-wrapper ul li i {
  color: #fff;
  border-color: #5d657b;
}
.darkTheme .icons-wrapper .icons > div span,
.darkTheme .icons-wrapper .icons li span,
.darkTheme .icons-wrapper ul > div span,
.darkTheme .icons-wrapper ul li span {
  color: rgba(255, 255, 255, 0.5);
}

/* ============ Calendar Css ============= */
.calendar-card .fc {
  height: 450px;
}
.calendar-card .fc#calendar-full {
  height: 600px;
}
.calendar-card .fc table {
  border: none;
}
.calendar-card .fc .fc-toolbar-title {
  font-size: 16px;
  font-weight: 500;
}
.calendar-card .fc .fc-button {
  background: transparent;
  border: none;
  color: #5d657b;
  text-transform: capitalize;
}
.calendar-card .fc .fc-button:focus {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  color: #4a6cf7;
}
.calendar-card .fc th {
  text-align: left;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-right: 0px;
}
.calendar-card .fc th a {
  color: #5d657b;
  font-weight: 400;
}
.calendar-card .fc .fc-day {
  border-width: 4px;
  background: #fff;
}
.calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame {
  background: rgba(74, 108, 247, 0.8);
}
.calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame a {
  color: #fff;
}
.calendar-card .fc .fc-day .fc-daygrid-day-frame {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background: #f9f9f9;
  border-radius: 6px;
}
.calendar-card .fc .fc-day .fc-daygrid-day-frame a {
  color: #5d657b;
}
.calendar-card .fc-theme-standard td,
.calendar-card .fc-theme-standard th {
  border-color: transparent;
}

/* ========= Theme Change Css ============ */
.darkTheme .calendar-card .fc .fc-button {
  color: #fff;
}
.darkTheme .calendar-card .fc th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.darkTheme .calendar-card .fc th a {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
}
.darkTheme .calendar-card .fc .fc-day {
  padding: 5px;
  background: #262d3f;
}
.darkTheme .calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame {
  background: #4a6cf7;
}
.darkTheme .calendar-card .fc .fc-day.fc-day-today .fc-daygrid-day-frame a {
  color: #fff;
}
.darkTheme .calendar-card .fc .fc-day .fc-daygrid-day-frame {
  background: #2f3546;
}
.darkTheme .calendar-card .fc .fc-day .fc-daygrid-day-frame a {
  color: #efefef;
}

/* =========== Kanban Css ============= */
.invisible {
  display: none;
}

.kanban-header {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 14px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 40px;
}
@media (max-width: 767px) {
  .kanban-header {
    display: block;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .kanban-header {
    display: flex;
  }
}
.kanban-header .kanban-header-members {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .kanban-header .kanban-header-members {
    margin-bottom: 20px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .kanban-header .kanban-header-members {
    margin-bottom: 0px;
  }
}
.kanban-header .kanban-header-members .members-image {
  padding-left: 20px;
}
.kanban-header .kanban-header-members .members-image img {
  width: 38px;
  height: 38px;
  border: 2px solid #fff;
  border-radius: 50%;
  overflow: hidden;
  margin-left: -20px;
}
.kanban-header .kanban-header-members .add-member-button {
  background: transparent;
  border: none;
  font-size: 14px;
  padding-left: 12px;
}
.kanban-header .kanban-header-members .add-member-button i {
  font-size: 12px;
  font-weight: 700;
  padding-right: 4px;
}
.kanban-header .add-task-button button i {
  font-weight: 700;
  padding-right: 4px;
  font-size: 14px;
}

.kanban-card-wrapper .card-wrapper-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 25px;
  text-transform: uppercase;
}

.kanban-card {
  background: #fff;
  border-radius: 6px;
  border-bottom: 3px solid;
  border-bottom-color: #4a6cf7;
  box-shadow: 0px 1px 4px rgba(26, 25, 85, 0.08);
  margin-bottom: 24px;
}
.kanban-card .kanban-button {
  padding: 25px 22px;
  text-align: left;
  width: 100%;
}
.kanban-card .kanban-card-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.kanban-card .priority {
  padding: 4px 8px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 500;
  background: rgba(74, 108, 247, 0.1);
  color: #4a6cf7;
}
.kanban-card .tooltip-icon {
  width: 25px;
  height: 25px;
  border-radius: 5px;
  background-color: #eff2f6;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kanban-card .tooltip-icon i {
  font-size: 14px;
  font-weight: 700;
}
.kanban-card h4 {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
}
.kanban-card .kanban-card-footer {
  margin-bottom: 25px;
  padding: 0px 22px;
}
.kanban-card .kanban-card-footer .more-btn::after {
  display: none;
}
.kanban-card.high {
  border-bottom-color: #fa5a78;
}
.kanban-card.high .priority {
  background: rgba(250, 90, 120, 0.1);
  color: #fa5a78;
}
.kanban-card.low {
  border-bottom-color: #42d29d;
}
.kanban-card.low .priority {
  background: rgba(66, 210, 157, 0.1);
  color: #42d29d;
}
.kanban-card.medium {
  border-bottom-color: #9b51e0;
}
.kanban-card.medium .priority {
  background: rgba(155, 81, 224, 0.1);
  color: #9b51e0;
}

.task-details-modal .modal-title,
.add-task-modal .modal-title {
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 767px) {
  .task-details-modal .modal-title,
.add-task-modal .modal-title {
    font-size: 20px;
  }
}
.task-details-modal .close-button,
.add-task-modal .close-button {
  position: absolute;
  right: 30px;
  top: 20px;
  color: #5d657b;
}
.task-details-modal .close-button:hover,
.add-task-modal .close-button:hover {
  color: #fa5a78;
}
.task-details-modal .modal-dialog,
.add-task-modal .modal-dialog {
  max-width: 1075px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .task-details-modal .modal-dialog,
.add-task-modal .modal-dialog {
    max-width: 95%;
  }
}
.task-details-modal .modal-dialog .modal-content,
.add-task-modal .modal-dialog .modal-content {
  box-shadow: 0px 12px 25px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
}
.task-details-modal .input-style-1 input,
.task-details-modal .input-style-1 textarea,
.add-task-modal .input-style-1 input,
.add-task-modal .input-style-1 textarea {
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 6px;
}
.task-details-modal .select-style-1 .select-position select,
.add-task-modal .select-style-1 .select-position select {
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 6px;
}
.task-details-modal .task-modal-left,
.add-task-modal .task-modal-left {
  padding: 40px;
  position: relative;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .task-details-modal .task-modal-left,
.add-task-modal .task-modal-left {
    padding-bottom: 0px;
  }
}
@media (max-width: 767px) {
  .task-details-modal .task-modal-left,
.add-task-modal .task-modal-left {
    padding: 25px;
    padding-bottom: 0px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .task-details-modal .task-modal-left,
.add-task-modal .task-modal-left {
    padding: 40px;
    padding-bottom: 0px;
  }
}
.task-details-modal .task-modal-left h5,
.add-task-modal .task-modal-left h5 {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 40px;
}
.task-details-modal .task-modal-right,
.add-task-modal .task-modal-right {
  padding: 40px;
  padding-top: 108px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .task-details-modal .task-modal-right,
.add-task-modal .task-modal-right {
    padding-top: 0px;
  }
}
@media (max-width: 767px) {
  .task-details-modal .task-modal-right,
.add-task-modal .task-modal-right {
    padding: 25px;
    padding-top: 0px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .task-details-modal .task-modal-right,
.add-task-modal .task-modal-right {
    padding: 40px;
    padding-top: 0px;
  }
}
.task-details-modal .task-modal-right .btn,
.add-task-modal .task-modal-right .btn {
  height: 42px;
  align-items: center;
}

.task-details-modal .modal-dialog {
  max-width: 820px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .task-details-modal .modal-dialog {
    max-width: 95%;
  }
}
.task-details-modal .modal-body {
  padding: 40px;
  padding-bottom: 20px;
}
@media (max-width: 767px) {
  .task-details-modal .modal-body {
    padding: 25px;
    padding-bottom: 5px;
  }
}
@media only screen and (min-width: 550px) and (max-width: 767px) {
  .task-details-modal .modal-body {
    padding: 40px;
    padding-bottom: 20px;
  }
}
.task-details-modal .priority {
  padding: 5px 10px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  margin-left: 15px;
}
.task-details-modal .desc {
  font-size: 18px;
  margin-bottom: 18px;
}
.task-details-modal .modal-meta-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -25px;
}
.task-details-modal .modal-meta-wrapper .modal-meta {
  padding: 0 25px;
  border-right: 1px solid #efefef;
  margin-bottom: 20px;
}
.task-details-modal .modal-meta-wrapper .modal-meta:last-child {
  border: none;
}

/* ========= Theme Change Css =========== */
.darkTheme .kanban-header {
  background: #262d3f;
  border-color: #2f3546;
}
.darkTheme .kanban-header .kanban-header-members .members-image img {
  border-color: #2f3546;
}
.darkTheme .kanban-card {
  background: #262d3f;
}
.darkTheme .kanban-card .tooltip-icon {
  background-color: #2f3546;
}
.darkTheme .add-task-modal .modal-content {
  background: #262d3f;
}

/* =========== File Manager css =========== */
.file-manager-cards-wrapper .icon-card .content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.file-manager-cards-wrapper .icon-card .content .file-size {
  font-size: 14px;
  font-weight: 600;
}

.recent-files .recent-file-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
}
.recent-files .recent-file-item:last-child {
  margin-bottom: 0px;
}
.recent-files .recent-file-item .icon {
  width: 42px;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background: #f6f6f8;
  margin-right: 14px;
}
.recent-files .recent-file-item h6 {
  font-size: 16px;
  font-weight: 500;
}

.file-item {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.file-item:last-child {
  margin-bottom: 0;
}
.file-item .icon {
  max-width: 40px;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  background: #f6f6f8;
  margin-right: 15px;
}
.file-item .file-size-wrapper {
  margin-top: 12px;
  position: relative;
  width: 100%;
  height: 5px;
  border-radius: 30px;
  background: #e6e9ed;
  overflow: hidden;
}
.file-item .file-size-wrapper .file-size {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  border-radius: 30px;
}

.file-card .card-body {
  display: flex;
  align-items: center;
}
.file-card .file-chart-content {
  padding-left: 15px;
}
.file-card .file-chart-content h5 {
  font-size: 20px;
  font-weight: 600;
}
.file-card .chart-wrapper {
  display: flex;
  align-items: center;
}
.file-card .chart-wrapper .ldBar-chart {
  position: relative;
}
.file-card .chart-wrapper .ldBar-chart .baseline {
  stroke: inherit;
  stroke-width: 10px;
  opacity: 0.1;
}
.file-card .chart-wrapper .ldBar-chart .mainline {
  stroke: inherit;
  stroke-width: 10px;
}
.file-card .chart-wrapper .ldBar-chart .ldBar-label {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: inherit;
}
.file-card .chart-wrapper .chart-body {
  width: 125px;
}
@media (min-width: 1400px) and (max-width: 1600px) {
  .file-card .chart-wrapper .chart-body {
    width: 115px;
  }
}
.file-card .chart-wrapper .chart-body .ldBar-label {
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #000;
}
.file-card .chart-wrapper .chart-body .ldBar-label:after {
  content: "%";
}
.file-card .clean-button {
  position: absolute;
  right: -26px;
  top: 50%;
  margin-top: -10px;
  transform: rotate(-90deg);
  background: #f98db4;
  color: #fff;
  border-radius: 5px 5px 0px 0px;
  border: none;
  padding: 4px 20px;
}

/* ======= Theme Change ========== */
.darkTheme .recent-files .recent-file-item .icon {
  background: #2f3546;
}
.darkTheme .file-item .file-size-wrapper,
.darkTheme .file-item .icon {
  background: #2f3546;
}
.darkTheme .file-card .chart-wrapper .chart-body .ldBar-label {
  color: #fff;
}

/* ========== Option Css ============== */
.option-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 55px;
  height: 55px;
  background: #4a6cf7;
  font-size: 24px;
  color: #fff;
  border: none;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  z-index: 99;
  border-radius: 4px;
}
.option-btn i {
  animation: rotate 2s infinite linear;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* .option-overlay {
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
  right: 0;
  top: 0;
  position: fixed;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  z-index: 9999;
} */
.option-overlay.show {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
  z-index: 999;
}

.option-header {
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.option-header h5 {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 4px;
}
.option-header p {
  font-size: 12px;
  line-height: 1.2;
}
.option-header .option-btn-close {
  background: none;
  border: 0px;
  margin-left: 5px;
}
.option-header .option-btn-close i {
  font-weight: 700;
  font-size: 14px;
}

.option-box {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;
  overflow-y: auto;
  background: #fff;
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 99999;
}
.option-box.show {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.option-box li {
  margin-right: 15px;
}
.option-box li:last-child {
  margin-right: 0px;
}
.option-box li button {
  display: block;
  background: none;
  border: none;
  padding: 8px 8px 8px 20px;
  position: relative;
}
.option-box li button::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border: 2px solid #4a6cf7;
  background: transparent;
  left: 0;
  top: calc(50% - 6px);
  border-radius: 50%;
}
.option-box li button.active {
  color: #4a6cf7;
}
.option-box li button.active::before {
  background: #4a6cf7;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .sidebar-nav-wrapper {
    -webkit-transform: translateX(-260px);
    -moz-transform: translateX(-260px);
    -ms-transform: translateX(-260px);
    -o-transform: translateX(-260px);
    transform: translateX(-260px);
  }
}
.sidebar-nav-wrapper.active {
  -webkit-transform: translateX(-260px);
  -moz-transform: translateX(-260px);
  -ms-transform: translateX(-260px);
  -o-transform: translateX(-260px);
  transform: translateX(-260px);
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .sidebar-nav-wrapper.active {
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -ms-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }
}
.sidebar-nav-wrapper .navbar-logo {
  text-align: center;
  padding: 0 25px;
  margin-bottom: 30px;
}
.sidebar-nav-wrapper .sidebar-nav .divider {
  padding: 5px 25px;
  width: 100%;
}
.sidebar-nav-wrapper .sidebar-nav .divider hr {
  height: 1px;
  background: #e2e2e2;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item {
  position: relative;
  margin: 5px 0px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a {
  color: #262d3f;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a::before {
  opacity: 1;
  visibility: visible;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a::after {
  content: "";
  font: normal normal normal 1em/1 "LineIcons";
  position: absolute;
  right: 25px;
  top: 16px;
  font-size: 12px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed {
  color: #5d657b;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed::before {
  opacity: 0;
  visibility: hidden;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed::after {
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul {
  padding: 0px 15px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li {
  margin-bottom: 10px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li:last-child {
  margin-bottom: 0px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a {
  font-size: 14px;
  font-weight: 400;
  border-radius: 6px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  border: 1px solid transparent;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
  color: #4a6cf7;
  border-color: rgba(74, 108, 247, 0.15);
  background: rgba(74, 108, 247, 0.1);
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a i {
  font-size: 16px;
  margin-right: 15px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a span.text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a span.pro-badge {
  background: #4a6cf7;
  color: #fff;
  padding: 1px 6px;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 10px;
}
/* .sidebar-nav-wrapper .sidebar-nav ul .nav-item a {
  display: flex;
  align-items: center;
  color: #5d657b;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  position: relative;
  z-index: 1;
  padding: 10px 25px;
} */
/* .sidebar-nav-wrapper .sidebar-nav ul .nav-item a::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background: #4a6cf7;
  border-radius: 0 3px 3px 0px;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
} */
/* .sidebar-nav-wrapper .sidebar-nav ul .nav-item a span.pro-badge {
  background: #4a6cf7;
  color: #fff;
  padding: 1px 6px;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 10px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item a .icon {
  margin-right: 12px;
  font-size: 18px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item a .icon svg {
  fill: currentColor;
} */
/* .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a, .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a.collapsed, .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a, .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed {
  color: #262d3f;
} */
.sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a::before, .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a.collapsed::before, .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a::before, .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed::before {
  opacity: 1;
  visibility: visible;
}

.sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
  color: #fff;
  border-color: #4a6cf7;
  background: #4a6cf7;
}
.sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active span.pro-badge, .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover span.pro-badge {
  background: #fff;
  color: #4a6cf7;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 11;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
}
@media only screen and (min-width: 1400px), only screen and (min-width: 1200px) and (max-width: 1399px) {
  .overlay {
    display: none;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .overlay.active {
    opacity: 1;
    visibility: visible;
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

.main-wrapper {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-left: 250px;
  min-height: 100vh;
  padding-bottom: 85px;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .main-wrapper {
    margin-left: 0;
  }
}
@media (max-width: 767px) {
  .main-wrapper {
    padding-bottom: 110px;
  }
}
.main-wrapper.active {
  margin-left: 0;
}
.main-wrapper .container-fluid {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 767px) {
  .main-wrapper .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.main-wrapper .footer {
  padding: 25px 0;
  justify-items: flex-end;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.main-wrapper .footer .copyright p a {
  color: inherit;
}
.main-wrapper .footer .copyright p a:hover {
  color: #4a6cf7;
}
@media (max-width: 767px) {
  .main-wrapper .footer .terms {
    margin-bottom: 10px;
    text-align: center;
  }
}
.main-wrapper .footer .terms a {
  color: #5d657b;
}
.main-wrapper .footer .terms a:hover {
  color: #4a6cf7;
}

.promo-box {
  box-shadow: 0px 10px 20px rgba(200, 208, 216, 0.3);
  padding: 24px 16px;
  text-align: center;
  max-width: 210px;
  margin: 0 auto;
  margin-top: 32px;
  border-radius: 4px;
}
.promo-box h3 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 4px;
}
.promo-box p {
  font-size: 12px;
  line-height: 1.5;
  margin-bottom: 16px;
}
.promo-box .main-btn {
  padding: 12px;
  width: 100%;
}

/* ====== Theme Change CSS ====== */
.darkTheme .sidebar-nav-wrapper {
  background: #262d3f;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a {
  color: #fff;
}
.darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed {
  color: #c2cbd6;
}
.darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.15);
  background: rgba(255, 255, 255, 0.1);
}
/* .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item a {
  color: #c2cbd6;
} */
.darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a, .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item.active > a.collapsed, .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a, .darkTheme .sidebar-nav-wrapper .sidebar-nav ul .nav-item:hover > a.collapsed {
  color: #fff;
}
.darkTheme .sidebar-nav-wrapper.style-2 {
  background: #090e34;
}
.darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children > a {
  color: #fff;
}
.darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children > a.collapsed {
  color: #c2cbd6;
}
.darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a.active, .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.nav-item-has-children ul li a:hover {
  color: #fff;
  border-color: #4a6cf7;
  background: #4a6cf7;
}
.darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item a {
  color: #c2cbd6;
}
.darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.active > a, .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item.active > a.collapsed, .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item:hover > a, .darkTheme .sidebar-nav-wrapper.style-2 .sidebar-nav ul .nav-item:hover > a.collapsed {
  color: #fff;
}
.darkTheme .promo-box {
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.2);
}
.profile-menu {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(-98px, 432px, 0px);
}
.dropdown-item:active {
  background-color: #ef5066;
}
.dropdown-item:active a {
  color: #fff !important;
}
.MuiInputLabel-root {
  padding-top: 15px !important;
}
.MuiOutlinedInput-root.Mui-focused label {
  display: none !important;
}
.truncate {
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.button-truncate {
  max-width: calc(100% - 20px);
}
.button-truncate .btn-interest {
  border-color: #ef5066;
  color: #ef5066;
}
.button-truncate .btn-interest:hover {
  color: #fff;
  background-color: #ef5066;
}

/*  public question  */
.question-content {
  border-radius: 20px;
}
.answer-questionnaire-select {
  border: 1px solid #C4C4C4;
  border-radius: 30px;
  padding: 0;
  color: #000000;
}
.answer-questionnaire-select .text-left {
  margin: 10px 40px 15px;;
}
.answer-questionnaire-select.checked {
  border: 1px solid #EE5368;
}
.answer-questionnaire-select .form-check-input {
  margin-left: -20px;
}
.answer-questionnaire-select .form-check-label {
  margin-left: 10px;
}
.answer-questionnaire-select:hover {
  background: #EBEBEB;
  /* border: 1px solid #000000; */
  border-radius: 30px;
}
.answer-questionnaire-select .form-check-input:checked[type=radio] {
  background: url(../images/solid.svg);
}
.answer-questionnaire-select .form-check-input {
  width: 24px;
  height: 24px;
}
.answer-questionnaire-select .form-check-input:checked {
  border: none;
}
.number-question {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #707070
}
.text-left {
  text-align: left !important;
}
.question-process {
  /* background: #EBEBEB;
  border-radius: 10px;
  height: 10px; */
  position: relative;
}
.question-process span {
  background: #EE5368;
  border-radius: 10px;
  height: 10px;
  position: absolute;
  top: 0;
  left: 0;
}
.card-workstype {
  max-width: 490px;
  margin: 0 auto;
}
.workstype-form-email {
  width: 300px;
  margin: 0 auto;
}
.workstype-form-email h6 {
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 60px;
  color: #000000;
}
.workstype-form-email .term-policy {
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #A3A3A3;
  margin-top: 15px;
}

.workstype-form-email .term-policy a {
  color: #625DF6
}
.workstype-form-email .workstyle-button {
  background: #625DF6;
  border-radius: 20px;
  padding: 11px 24px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  border: none !important;
}
.workstype-form-email .skip-step {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: #A3A3A3;
}
.workstyle-archetype .archetype-header h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.workstyle-archetype .archetype-header .workstyle-name.view-profile {
  /* width: calc(100% - 170px); */
  margin-bottom: 0;
}
.workstyle-archetype .archetype-header .workstyle-name {
  width: calc(100% - 187px);
  margin-bottom: 36px;
}
.workstyle-archetype .archetype-header .workstyle-name .code  {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  margin-top: 10px;
}
.workstyle-archetype .archetype-header .button-share {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
  background: #625DF6;
  border-radius: 20px;
  border: none;
  padding: 9px 15px;
}
.dashboard .button-view-profile,
.persona_styles .button-view-profile,
.interest .button-view-profile,
.workstyle-archetype .archetype-header .button-view-profile {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  background: #D9D9D9;
  border-radius: 20px;
  padding: 8px 20px;
  border: none;
}
.button-view-profile {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #000000;
  background: #D9D9D9;
  border-radius: 20px;
  padding: 8px 20px;
  border: none;
}
.interst 
.workstyle-archetype .archetype-header .button-share img {
  padding: 0 5px;
}

.workstyle-archetype .archetype-description p {
  margin-bottom: 30px;
}
.workstyle-tab .item {
  width: 25%;
  text-align: center;
  margin-right: 0;
  font-size: 16px;
  line-height: 30px;
  font-weight: 400;
  color: #686868;
}
.workstyle-tab .item.active {
  font-weight: 600;
  color: #000000;
}
.workstyle-tab button::after {
  background: #DE5D68 !important;
  height: 7px !important;
}

.workstyle-dimension .dimension-style .dimension-style-name {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
  margin-bottom: 10px;
}
.workstyle-dimension .dimension-style .persona-container {
  margin-top: 3px;
}
/* .workstyle-dimension .dimension-style .persona-container .percentage {
  font-size: 14px;
  color: #D9D9D9;
} */
.workstyle-dimension .dimension-style .persona-container .persona-style {
  font-weight: 600;
  font-size: 12px;
  line-height: 30px;
  color: #D9D9D9;
  text-transform: uppercase;
}
.workstyle-dimension.row>* {
  padding-right: 3rem;
}
.workstyle-dimension .dimension-style .des {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  margin-bottom: 50px;
  margin-top: 10px;
}
.edit-profile img {
  width: 24px;
  height: 24px;
  margin-right: 3px;
}

.edit-profile .edit-text {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #000000;
}
.card-profile .user-name {
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  color: #000000;
}
.card-profile .code {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.persona-style-breakdown {
  font-size: 12px;
  line-height: 15px;
  /* text-transform: uppercase; */
}
.workstyle-title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 25px;
}

.workstyles-list-section .page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}
.search {
  position: relative;
}
.search .input-search {
  background: #FFFFFF;
  border: 1px solid #C6C6C6;
  border-radius: 20px;
  height: 38px;
  width: 100%;
  max-width: 100%;
  padding-left: 45px;
  padding-right: 20px;
}
.search .icon-search-right {
  position: absolute;
  top:8px;
  left: 15px;

}
.search .close-round-right {
  position: absolute;
  top: 10px;
  right: 10px;
}
.workstyles-list-section .style-card {
  border-radius: 20px;
  background-color: #fff;
}

.icon-eye {
  background-image: url("../images/lightning_ring_duotone_line.svg");
  /* background-position: -14px -13px; */
  background-repeat: no-repeat;
  overflow: hidden;
  height: 30px;
  width: 30px;
  display: inline-block;
  margin-right: 5px;
}
.icon-expand-right {
  background-image: url("../images/expand_right.svg");
  /* background-position: -14px -13px; */
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 5px;
}

.icon-search-right {
  background-image: url("../images/search_light.png");
  /* background-position: -14px -13px; */
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 5px;
}

.close-round-right {
  background-image: url("../images/close_round_light.png");
  /* background-position: -14px -13px; */
  background-repeat: no-repeat;
  overflow: hidden;
  height: 18px;
  width: 18px;
  display: inline-block;
  /* margin-right: 5px; */
}

.close_ring_light {
  background-image: url("../images/close_ring_light.png");
  /* background-position: -14px -13px; */
  background-repeat: no-repeat;
  overflow: hidden;
  height: 34px;
  width: 34px;
  display: inline-block;
  /* margin-right: 5px; */
}

.user-cicrle-light {
  background-image: url("../images/user_cicrle_light.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}
.layers-duotone {
  background-image: url("../images/layers_duotone.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}
.coworker-icon {
  background-image: url("../images/co-worker.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}
.workstyle-icon {
  background-image: url("../images/workstyle-icon.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.dashboard-icon {
  background-image: url("../images/dashboard.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.lenses-icon {
  background-image: url("../images/lenses.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.people-icon {
  background-image: url("../images/people.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.team-icon {
  background-image: url("../images/team.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 25px;
  width: 35px;
  background-position-x: 5px;
  display: inline-block;
}
.setting-icon {
  background-image: url("../images/setting.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.dell-duotone-icon {
  background-image: url("../images/dell_duotone.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.close-circle-navy {
  background-image: url("../images/close-circle-navy.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
  cursor: pointer;
}

.flag-alt-fill {
  background-image: url("../images/Flag_alt_fill.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.check-mark-solid {
  background-image: url("../images/check-mark-solid.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
}
.check-fill {
  background-image: url("../images/check_fill.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
}

.remove-doutone-icon {
  background-image: url("../images/Remove_duotone.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 30px;
  width: 30px;
  display: inline-block;
}

.workstyle-dimension .dimension-style .title {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}

.workstyle-dimension .dimension-style .trait-des {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 35px;
}
/* .workstyle-detail-header {
  margin-bottom: 50px;
} */

.workstyle-detail-header .page-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}
.workstyle-detail-header .persona-name {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #000000;
}
.pagination-container li button {
  border-radius: 5px;
  background: #EFEFEF;
  color: #000000 !important;
}
.pagination-container li button.Mui-selected {
  background: #DADADA;
}
.co-worker-list .heading {
  margin-bottom: 25px;
}
.co-worker-list .heading .label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
}
.co-worker-list .item .image {
  width: 70px;
  height: 70px;
  background: #D9D9D9;
  border-radius: 10px;
}
.co-worker-list .item h5 {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
}
.co-worker-list .item .team {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  align-items: center;
  display: flex;
  justify-content: center;
}

.co-worker-list .item .workstyle,
.co-worker-list .item .arrow-coworker {
  align-items: center;
  display: flex;
  justify-content: center;
}
.interest .incomplete {
  background: #F5F5F5;
  color: #C0C0C0;
}
.card-profile .breakdown-title {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
  text-align: center;
  margin-bottom: 30px;
}
.sidebar-nav .menu-name {
  margin-bottom: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.5);
  /* text-align: center; */
  padding-left: 30px;
  text-transform: uppercase;
}
.justify-content-space-between {
  justify-content: space-between !important;
  align-items: center !important;
}
.justify-content-flex-start {
  justify-content: start !important;
  align-items: center !important;
}
.onboarding {
  border-radius: 20px;
  background: #FFF;
  padding: 40px 52px 60px;
}
.onboard-content .onboard-number .number {
  color: #000;
  font-size: 26px;
  font-weight: 600;
  border-radius: 50%;
  background: #D9D9D9;
  padding: 10px 23px 10px 23px;
  margin-right: 20px;
  position: relative;
  /* width: 59px;
  height: 59px; */
}
.onboard-content .onboard-number .number .checked {
  position: absolute;
  top: -5px;
  right: -5px;
}
.onboard-content .onboard-number .des h3 {
  color: #000;
  font-size: 18px;
  font-family: Inter;
  font-weight: 600;
}

.onboard-content .onboard-number .des span {
  color: #000;
  font-size: 12px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18px;
}
.onboard-button {
  padding: 12px 20px;
}
.onboard-button.disable {
  opacity: 0.5;
}
.spacing {
  background: #E4E4E4;
  width: 3px;
  height: 27px;
  display: block;
  margin-left: 28px;
  margin-top: 5px;
  margin-bottom: 5px;
}
/* Setting page */
.setting-page .page-title{
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 38px;
}

.setting-page .session-title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 7px;
}
.setting-page .session-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}

.setting-page .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 5px;
  border: 1px solid #7D7D7D;
}

.setting-page .css-gtwism-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 0px 0px 5px 25px;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.setting-page .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 5px 0px 5px 25px;
}

.setting-page .list-day-setting .day-setting-item .MuiTypography-root {
  border-radius: 20px;
  background: #EFEFEF;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  padding: 8px 13px;
}

.setting-page .list-day-setting .day-setting-item.active .MuiTypography-root {
  border-radius: 20px;
  background: #DADADA;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  padding: 8px 13px;
}

.setting-page .btn-questionnaire {
  border-radius: 20px;
  background: #D9D9D9;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 13px 25px;
}

.public-channel-icon {
  background-image: url("../images/send_hor_duotone_line.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.date-range-icon {
  background-image: url("../images/date_range_duotone.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.time-send-icon {
  background-image: url("../images/time_duotone_line.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}

.timezone-icon {
  background-image: url("../images/timezon_icon.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  margin-left: 5px;
  margin-top: 5px;
  display: inline-block;
  background-size: 35px 35px;
}

.questionnaire-icon {
  background-image: url("../images/questionnaire_icon.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 35px;
  width: 35px;
  display: inline-block;
}
.team .app-panel .app-panel__body {
  padding: 40px;
}
.team .no-team {
  max-width: 400px;
  margin: 0 auto;
}
.team .no-team .title {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.team .no-team .team-description {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}
.team .no-team .btn-add-new {
  border-radius: 20px;
  background: #D9D9D9;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border: none;
  padding: 12px 25px;
  margin: 0 auto  ;
}
.team .app-panel {
  border-radius: 20px;
  background: #FFF;
}
.add-plus {
  background-image: url("../images/add-plus.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 12px;
  width: 12px;
  display: inline-block;
  /* margin-right: 10px; */
  background-position: bottom;
}

.arhive_load_light {
/* Arhive_load_light */
  background-image: url("../images/Arhive_load_light.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
  /* margin-right: 10px; */
  background-position: bottom;
}
.edit_duotone_line  {
  background-image: url("../images/edit_duotone_line.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 10px;
  background-position: bottom;
}
.trash_duotone_line {
  background-image: url("../images/trash_duotone_line.png");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 24px;
  width: 24px;
  display: inline-block;
  margin-right: 10px;
  background-position: bottom;
}
.expand_down_light {
  background-image: url("../images/expand_down_light.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 32px;
  width: 24px;
  display: inline-block;
  margin-left: 10px;
  background-position: bottom;
}
.team-overview-icon {
  background-image: url("../images/team-overview-icon.svg");
  background-repeat: no-repeat;
  overflow: hidden;
  height: 22px;
  width: 6px;
  display: inline-block;
  background-position: bottom;
}
.create-team-dialog .MuiPaper-root {
  border-radius: 20px;
  background: #FFF;
  max-width: 510px;
}

.create-team-dialog h2 {
  padding: 27px 38px;
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.create-team-dialog .tab-title {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  text-transform: capitalize;
}

.create-team-dialog .tab-title.Mui-selected {
  color: #000;
}

.create-team-dialog .MuiTabs-indicator {
  background: rgba(0, 0, 0, 0.50);
  height: 3px;
}

.create-team-dialog .form-title {
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}

.btn-add-new {
  border-radius: 20px;
  background: #D9D9D9;
  border: none;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 17px 13px 20px;
  box-shadow: none;
  margin-top: 37px;
}
.btn-add-new:hover {
  background: #D9D9D9;
}

.create-team-dialog .btn-add-new {
  border-radius: 20px;
  background: #D9D9D9;
  border: none;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 17px 13px 20px;
  box-shadow: none;
  margin-top: 37px;
}
.create-team-dialog .css-1rwt2y5-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  border: none;
  /* color: #000; */
}
.create-team-dialog .btn-add-new:hover {
  background: #D9D9D9;
}

.input-auto-team .input-auto-team-wrap {
  width: 100% !important;
  border-radius: 5px;
  border: 1px solid #D2D2D2;
}

.create-team-dialog .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 14px;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  border: 1px solid #D2D2D2;
  background: #FFF;
}

.create-team-dialog .input-auto-team .input-auto-team-wrap input
{
  padding: 5px 14px;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;  
  background: #FFF;
}

.create-team-dialog .input-auto-team .input-auto-team-wrap input::placeholder {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.create-team-dialog .form-field {
  margin-bottom: 0px !important;
}

.create-team-dialog .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none;
}

.create-team-dialog .css-hz1bth-MuiDialog-container {
  align-items: flex-start;
  margin-top: 150px;
}
.team h2 {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.team .team-list .label {
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.team .team-list .team-name {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.team .team-list .team-people {
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.team .team-list .heading {
  margin-bottom: 45px;
}
.team .team-list .item {
  margin-bottom: 65px;
}
.team .team-list .item.people {
  margin-bottom: 15px;
}

.team .team-list .item:last-child {
  margin-bottom: 25px;
}
.delete-team-dialog .MuiPaper-root {
  border-radius: 20px !important;
  background: #FFF;
  width: 473px;
  /* height: 234px; */
}
.delete-team-dialog .MuiPaper-root h2 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.delete-team-dialog .MuiPaper-root .msg {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 31px 20px 40px 0;
  margin-bottom: 0 !important;
}
.delete-team-dialog .MuiPaper-root .btn-cancel {
  border-radius: 20px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: none;
  padding: 12px 25px;
}
.delete-team-dialog .MuiPaper-root .btn-delete-team {
  border-radius: 20px;
  background: #EE4757;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: none;
  padding: 12px 25px;
  margin-left: 5px;
}
.team-list .item .team {
  width: 180px;
  margin: 0 auto;
}
.team-list .item .team .css-e53awj-MuiStack-root {
  flex: 0 1 auto;
}
.team .team-header {
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: none !important;
}
.team .team-header .persona-name {
  text-decoration-line: underline;
}
.profile-list .profile-name {
  color: #000;
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}
.profile-list .team-name {
  color: #000;
  /* font-size: 16px; */
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.team-people .avatar-item .avatar-image {
  border: 2px solid #fff;
}

.team-people .avatar-more {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  color: #808080;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.team-plus {
  /* background-image: url("../images/team-plus.png"); */
  /* background-repeat: no-repeat; */
  /* overflow: hidden; */
  height: 30px;
  width: 30px;
  /* display: inline-block; */
  /* margin-left: 15px; */
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-team-overview .title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
  margin-bottom: 40px;
}
.dashboard-team-overview .table .th-column {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 25%;
  padding-left: 20px;
  padding-right: 20px;
}
/* .dashboard-team-overview .table td {
  padding-left: 20px;
  padding-right: 20px;
} */

.dashboard-team-overview .table .td-team-name {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.dashboard-team-overview .table .dimension-scale-name {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}

.dashboard-team-overview .table .dimension-scale-value {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
}
.company-score-category .score-item .score-item-container,
.score-item-container {
  padding: 22px;
  border-radius: 20px;
  color: #FFF;
}

.company-score-category .score-item .score-item-container .category-name,
.score-item-container .category-name  {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.company-score-category .score-item .score-item-container .scale-name,
.score-item-container .scale-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.company-score-category .score-item .score-item-container .top,
.score-item-container .top {
  justify-content: space-between;
  margin-bottom: 15px;
}

.company-score-category .score-item .score-item-container .scrore,
.score-item-container .scrore {
  font-size: 45px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.company-score-category .score-item .score-item-container .rate,
.score-item-container .rate {
  margin-top: 10px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.company-score-category .score-item .score-item-container .more {
  margin-top: 20px;
}
.company-score-category .score-item .score-item-container .more .btn-more {
  border-radius: 20px;
  background: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px 25px 13px 30px;
}
.company-score-category .score-item .score-item-container .company-process {
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.50);
}
.company-score-category .score-item .score-item-container .company-process span {
  background: #FFF;
}
.dashboard-chart {
  border-radius: 20px;
  background: #FFF;
  padding: 40px 50px;
  margin-top: 40px;
}

.dashboard-chart .select {
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  background: #F6F6F6;
  color: #525252;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 20px;
  margin-left: 10px;
  margin-right: 10px;
}
.heading .select {
  border-radius: 5px;
  border: 1px solid #D3D3D3;
  background: #F6F6F6;
  color: #525252;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.dashboard-resource .resource-item {
  border-radius: 20px;
  background: rgba(26, 155, 240, 0.26);
  padding: 15px 20px;
  margin: 15px;
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; 
}

/* Archetype */
.team-archetypes .title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 45px;
}
.team-archetypes .archetype-item .user-name {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.team-archetypes .archetype-item .description {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  margin-top: 20px;
}
.dashboard-team .company-score .score-item-indicator .description {
  color: #000;
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}
.dashboard-team .company-score .score-item-indicator .dot-point h4 {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  margin-top: 20px;
}
.dashboard-team .company-score .score-item-indicator .dot-point ul {
  list-style-type: disc;
  margin-left: 20px;
}
.team-strengths .strength-tab .item {
  background: #ECECEC;
  color: #858585;
  border-radius: 20px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 8px 20px;
  margin-right: 0 !important;
}
.team-strengths .strength-tab .item.active {
  background: #EE5368;
  color: #FFF;
}
.team-strengths .strength-tab .item::after {
  display: none;
}

.team-strengths .tab-style-1 nav {
  border-bottom: none;
}
.team-strengths .people .image {
  border-radius: 10px;
  background: #D9D9D9;
  width: 70px;
  height: 70px;
}
.team-strengths .people-title {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-bottom: 12px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.10);
}
.team-strengths .people .profile-name {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.team-strengths .people .team-role {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 8px;
}
/* Lenses */
/* .lenses-section .lenses h3.title {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 7px;
}
.lenses-section .lenses .description {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
}
.lenses-section .lenses .btn-analyse {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 12px;
  border-radius: 5px;
  background: #D9D9D9;
}
.lenses-section .lenses .persona .persona-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal; 
  margin-bottom: 17px;
}
.lenses-section .lenses .persona .description {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.lenses-section .lenses .breakdown-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
} */

.team-first-character {
  color: #FFF;
  font-family: Inter;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 50%;
  background: #1A9BF0;
  padding: 12px 22px 10px;
  margin-right: 20px;
}
.trait-label {
  color: #FFF;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 20px;
  background: #EE5368;
  padding: 8px 27px;
}
.lenses-select__control {
  width: 100%;
  background: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 10px !important;
  padding: 9px;
  /* padding-right: 38px; */
  color: #5d657b;
  appearance: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.lenses-select__indicator-separator {
  display: none;
}

.lenses-select__menu {
  z-index: 99;
}
.lenses-select__menu .lenses-select__group-heading {
  font-size: 16px;
  font-weight: 500;
}
.lenses-select__placeholder {
  text-wrap: nowrap;
}

.lenses-select__menu .lenses-select__option {
  font-size: 14px;
}
/* .lenses-section .lenses .analyse-description {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
} */
/* .lenses-section .lenses .btn-analyse {
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 8px 18px 10px 10px;
  text-transform: capitalize;
  color: #000;
} */
.option-image {
  width: 23px;
  height: 23px;
  flex-shrink: 0;
  border-radius: 10px;
  background: #D9D9D9;
}

.section-panel {
  border-radius: 20px;
  background: #FFF;
  padding: 45px;
  color: #000;
}
.align-vertical {
  display: flex;
  align-items: center;
}

.admin-access-dialog h2 {
  padding: 27px 26px;
}

.drag-import-people {
  border-radius: 20px;
  border: 2px dashed var(--Navy, #00004C);
  display: flex;
  /* width: 622px; */
  padding: 50px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}
.drag-import-people .lable {
  color: var(--Navy, #00004C);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Aeonik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 137.5% */
}
.drag-import-people .lable .browse {
  color: var(--Navy, #00004C);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Aeonik;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  text-decoration-line: underline;
}
.import-people-dialog .description{
  color: #000;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 128.571% */
  margin-bottom: 30px;
}
.import-people-dialog .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: 20px;
  max-width: 800px;
}
.import-people-dialog .btn-next-import, 
.import-people-dialog .btn-next-import:hover {
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  border-radius: 5px;
  background: #D9D9D9;
  padding: 10px 20px;
  text-transform: capitalize;
  box-shadow: none;
}
.import-people-dialog .btn-next-import.Mui-disabled {
  color: rgba(0, 0, 0, 0.26);
}
.error-description {
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
  border-radius: 5px;
  background: rgba(255, 0, 0, 0.25);
  padding: 10px 20px;
}
.import-title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.cHZEeX {
  width: 100% !important;
}
.drag-container label {
  width: 100% !important;
}
.import-people-dialog .table-import th {
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.import-people-dialog .table-import td {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.add-more .btn-add,
.add-more .btn-add:hover,
.add-more .btn-import,
.add-more .btn-import:hover {
  border-radius: 20px;
  background: #D9D9D9;
  padding: 10px 20px;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: none;
}
.add-more .btn-import,
.add-more .btn-import:hover {
  padding: 5px 15px !important;
  margin-left: 6px;
}
.delete-person-dialog .dialog-title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.delete-person-dialog .MuiPaper-root h2 {
  padding: 35px 40px 25px 55px;
}
.delete-person-dialog .MuiDialogContent-root {
  padding-top: 0px;
  padding-bottom: 25px;
  padding-left: 55px;
  padding-bottom: 40px;
}

.delete-person-dialog .MuiPaper-root .msg {
  padding: 0px 20px 40px 0;
}
.edit-person-dialog .btn,
.delete-person-dialog .btn {
  border-radius: 5px !important;
  border: 1px solid #D9D9D9 !important;
  background: #F8F8F8 !important;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: none;
}
.edit-person-dialog .btn-delete-team,
.delete-person-dialog .btn-delete-team {
  border-radius: 5px;
  background: #D9D9D9 !important;
  color: #222 !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.edit-person-dialog .MuiPaper-root .btn-cancel {
  border-radius: 20px;
  border: 1px solid #D9D9D9;
  background: #FFF;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: none;
  padding: 12px 25px;
}
.edit-person-dialog .MuiPaper-root .btn-delete-team {
  border-radius: 20px;
  background: #EE4757;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: none;
  padding: 12px 25px;
  margin-left: 5px;
}
.edit-person-dialog {
  max-width: 716px;
  margin: 0 auto;
}
.edit-person-dialog .css-tlc64q-MuiPaper-root-MuiDialog-paper {
  border-radius: 20px;
  background: #FFF;
}

.edit-person-dialog .field-input {
  border-radius: 5px;
  border: 1px solid #7D7D7D;
  background: #FFF; 
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 25px 12px;
}
.edit-person-dialog .lable-select {
  top: -12px !important;
  color: rgba(34, 34, 34, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.people-first-character {
  border-radius: 50%;
  background: #D9D9D9;
  width: 70px;
  height: 70px;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-get-started,
.btn-get-started:hover {
  color: #FFF;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 50px;
  background: #636363;
  padding: 17px 30px;
}
.m-auto {
  margin: 0 auto;
}
.schedule-page .wellcome-message p {
  margin-bottom: 10px; 
}
.company-name {
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px; /* 120% */
}
.handle-pointer {
  cursor: pointer;
}

.select-team .css-gtwism-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 0;
}
.select-team input {
  height: 40px;
  border: 1px solid #7D7D7D;
  padding: 0 4px 0 6px !important;
  background: none;
}
.select-team fieldset {
  border: none;
}
.select-team .MuiAutocomplete-inputRoot {
  padding: 0 !important;
}
.select-team label {
  top: -5px !important;
  color: rgba(34, 34, 34, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.select-team label.Mui-focused,
.select-team label.MuiFormLabel-filled {
  top: -13px !important;
  color: rgba(34, 34, 34, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
}
.edit-people-field {
  margin-top: 20px;
}
.edit-people-field label {
  top: -10px !important;
  color: rgba(34, 34, 34, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.edit-people-field input,
.edit-people-field input:focus {
  border-radius: 5px;
  border: 1px solid #7D7D7D;
  background: #FFF;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 12px 10px;
  width: 100%;
}
.edit-people-field fieldset {
  border: none;
}
.select-role label {
  top: -15px;
}
.select-role fieldset {
  top: 0;
  padding: 20px;
  border: 1px solid #7D7D7D;
}
.add-role {
  color: #008FFB;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}
.header .header-right .people-first-character {
  font-size: 18px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-10 {
  font-size: 10px;
}
.font-size-8 {
  font-size: 8px;
}
.learn-more {
  color: #000;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-decoration-line: underline;
}
.select-role .form-field {
  margin-bottom: 5px !important;
}
.profile-modal .MuiDialog-container {
  margin-top: 0;
}

.profile-info input {
  /* border-radius: 5px; */
  border: 1px solid #7D7D7D;
  /* background: #FFF;
  color: #222;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal; */
  padding: 16px !important;
  /* width: 100%; */
}

/* .profile-modal label {
  top: 3px !important;
  color: rgba(34, 34, 34, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.profile-modal h2 {
  padding: 15px 25px;
} */
.profile-modal label.Mui-focused {
  display: none;
}
.profile-modal .buttons-group li {
  margin: 5px;
}
.profile-modal .buttons-group { 
  margin: 0;
}
.profile-modal .MuiOutlinedInput-root {
  padding: 6px 0 !important;
}
.profile-modal .btn-update-profile {
  background: #D9D9D9 !important;
    color: #222 !important;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-shadow: none;
  padding: 12px 25px;
  margin-left: 5px;
}
.profile-modal fieldset {
  border: none;
}
.profile-remove .confirm-button,
.profile-remove .confirm-button:hover {
  border-radius: 20px;
  background: #ef5066;
  border: none;
  color: #000;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 7px;
  box-shadow: none;
  text-transform: none;
  text-decoration: none;
  text-decoration-line: none;
  color: #fff;
}
.text-right {
  text-align: right !important;
}
.ml-0 {
  margin-left: 0 !important;
}

.dominant-trait .title {
  color: #000;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  margin-bottom: 40px;
}
.dominant-trait .round-balance {
  position: absolute;
  top:-6px;
  left: calc( 50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.round-balance {
  position: absolute;
  top:-6px;
  left: calc( 50% - 10px);
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.cursor-pointer {
  cursor: pointer;
}
.had-value {
  padding: 2px 10px !important;
  min-height: 30px;
}
.float-right {
  float: right;
}
.menu-area {
  z-index: 100;
}
.show-menu {
  position: absolute;
  inset: 0px 0px auto auto;
  margin: 0px;
  transform: translate3d(0px, 48px, 0px);
}
.header .header-right .profile-box .dropdown-menu li.divider {
  background: rgba(28, 28, 28, 0.05);
}
.import-button svg {
  fill: none;
}
.z-index-10 {
  z-index: 10;
}
.z-index-9 {
  z-index: 9;
}
.main-btn {
  border-radius: 8px;
}
.header .header-right button span.people-first-character  {
  border: none;
}
.header-search {
  position: relative;
}
.header-search form input {
  width: 100%;
  border: 1px solid #efefef;
  background: rgba(239, 239, 239, 0.5);
  border-radius: 8px;
  padding: 10px 10px 10px 44px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.header-search form button {
  position: absolute;
  border: none;
  background: transparent;
  left: 16px;
  top: 0;
  height: 46px;
  color: #5d657b;
  font-weight: 700;
}
.wellcome-message h3 {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 10px;
}
.wellcome-message p {
  font-size: 14px;
}
.sidebar-nav-wrapper .sidebar-nav ul .nav-item a {
  color: rgba(0,0,0,.5);
}