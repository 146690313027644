 /* =========== Sidebar css =========== */

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 999999;

  .spinner {
    width: 80px;
    height: 80px;
    border: 5px solid $primary;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spinner 0.90s linear infinite;
  }
}

@keyframes spinner {
  from {
    @include transform (rotate(0deg));
  }
  to {
    @include transform (rotate(360deg));
  }
}