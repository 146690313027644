/* =========== form elements css ========== */

/* ===== input style ===== */
.input-style-1 {
  position: relative;
  margin-bottom: 30px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    display: block;
    margin-bottom: 10px;
  }

  input,
  textarea {
    width: 100%;
    background: rgba($light, 0.5);
    border: 1px solid $light-2;
    border-radius: 4px;
    padding: 16px;
    color: $gray;
    resize: none;
    transition: all 0.3s;

    @media #{$xs} {
      font-size: 14px;
    }

    &:focus {
      border-color: $primary;
      background: $white;
    }

    &[type="date"],
    &[type="time"] {
      background: transparent;
    }

    &.light-bg {
      &[type="date"],
      &[type="time"] {
        background: rgba($light, 0.5);
      }
    }
    &.light-bg {
      &[type="date"]:focus,
      &[type="time"]:focus {
        background: $white;
      }
    }
  }
}

.input-style-2 {
  position: relative;
  margin-bottom: 30px;
  z-index: 1;

  label {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    display: block;
    margin-bottom: 10px;
  }

  input,
  textarea {
    width: 100%;
    background: rgba($light, 0.5);
    border: 1px solid $light-2;
    border-radius: 4px;
    padding: 16px;
    color: $gray;
    resize: none;
    transition: all 0.3s;

    &:focus {
      border-color: $primary;
      background: $white;
    }

    &[type="date"],
    &[type="time"] {
      background: transparent;
    }
  }

  input[type="date"]::-webkit-inner-spin-button,
  input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
  }

  input[type="date"] ~ .icon {
    z-index: -1;
  }

  .icon {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 17px;
  }
}

.input-style-3 {
  position: relative;
  margin-bottom: 30px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    display: block;
    margin-bottom: 10px;
  }

  input,
  textarea {
    width: 100%;
    background: rgba($light, 0.5);
    border: 1px solid $light-2;
    border-radius: 4px;
    padding: 16px;
    padding-left: 45px;
    color: $gray;
    resize: none;
    transition: all 0.3s;

    &:focus {
      border-color: $primary;
      background: $white;
    }
  }

  .icon {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    padding: 16px;
  }
}

/* ========= select style ========== */
.select-style-1 {
  margin-bottom: 30px;

  label {
    font-size: 14px;
    font-weight: 500;
    color: $dark;
    display: block;
    margin-bottom: 10px;
  }

  .select-position {
    position: relative;

    &::after {
      border-bottom: 2px solid $gray;
      border-right: 2px solid $gray;
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      margin-top: -5px;
      pointer-events: none;
      position: absolute;
      right: 16px;
      top: 50%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
    }

    &.select-sm {
      &::after {
        margin-top: -8px;
      }

      select {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
      }
    }

    select {
      width: 100%;
      background: transparent;
      border: 1px solid $light-2;
      border-radius: 10px;
      padding: 16px;
      padding-right: 38px;
      color: $gray;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      @include transition(0.3s);

      &:focus {
        border-color: $primary;
        outline: none;
      }

      &.light-bg {
        background: rgba($light, 0.5);
      }
      &.light-bg:focus {
        background: $white;
      }

      &.radius-30 {
        border-radius: 30px;
      }
    }
  }
}

.select-style-2 {
  margin-bottom: 30px;

  .select-position {
    position: relative;

    &.select-sm {
      &::after {
        margin-top: -8px;
      }
      &::before {
        margin-top: 0;
      }

      select {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
      }
    }

    &::before,
    &::after {
      content: "";
      display: block;
      height: 8px;
      width: 8px;
      pointer-events: none;
      position: absolute;
      right: 16px;
      top: 50%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
    }

    &::before {
      margin-top: 0px;
      border-bottom: 1px solid $gray;
      border-right: 1px solid $gray;
    }

    &::after {
      margin-top: -8px;
      border-top: 1px solid $gray;
      border-left: 1px solid $gray;
    }

    select {
      width: 100%;
      background: transparent;
      border: 1px solid $light-2;
      border-radius: 10px;
      padding: 16px;
      padding-right: 38px;
      color: $gray;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      @include transition(0.3s);

      &:focus {
        border-color: $primary;
        outline: none;
      }

      &.light-bg {
        background: rgba($light, 0.5);
      }
      &.light-bg:focus {
        background: $white;
      }

      &.select-sm {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
      }
    }
  }
}

.select-style-3 {
  margin-bottom: 30px;

  .select-position {
    position: relative;

    &::after {
      border-bottom: 2px solid $gray;
      border-right: 2px solid $gray;
      content: "";
      display: block;
      height: 10px;
      width: 10px;
      margin-top: -7px;
      pointer-events: none;
      position: absolute;
      right: 0px;
      top: 50%;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: all 0.15s ease-in-out;
      transition: all 0.15s ease-in-out;
    }

    &.select-sm {
      &::after {
        margin-top: -8px;
      }

      select {
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 14px;
      }
    }

    select {
      width: 100%;
      background: transparent;
      border: transparent;
      border-radius: 10px;
      padding-right: 38px;
      color: $black;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      @include transition(0.3s);

      &:focus {
        border-color: $primary;
        outline: none;
      }

      &.light-bg {
        background: rgba($light, 0.5);
      }
    }
  }
}

.toggle-switch {
  padding-left: 60px;
  min-height: 30px;

  .form-check-input {
    width: 50px;
    height: 28px;
    margin-left: -60px;
    cursor: pointer;
  }

  label {
    margin-top: 6px;
    font-size: 14px;
    color: $dark;
    cursor: pointer;
    user-select: none;
  }
}

//* ===== checkbox style  */
.checkbox-style {
  padding-left: 40px;
  min-height: 22px;

  .form-check-input {
    width: 22px;
    height: 22px;
    border-radius: 4px;
    margin-left: -40px;
    cursor: pointer;
    margin-top: 6px;

    &:checked {
        background-color: $primary;
        border-color: $primary;
      }

    &:disabled {
      cursor: auto;
    }
  }
  .form-check-input:disabled ~ label {
    cursor: auto;
  }
  label {
    margin-top: 6px;
    cursor: pointer;
    user-select: none;
  }

  &.checkbox-success {
    .form-check-input {
      &:checked {
        background-color: $success;
        border-color: $success;
      }
    }
  }
  &.checkbox-warning {
    .form-check-input {
      &:checked {
        background-color: $warning;
        border-color: $warning;
      }
    }
  }
  &.checkbox-danger {
    .form-check-input {
      &:checked {
        background-color: $danger;
        border-color: $danger;
      }
    }
  }
}

//* ===== radio style  */
.radio-style {
  padding-left: 40px;
  min-height: 22px;

  .form-check-input {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-left: -40px;
    cursor: pointer;
    margin-top: 7px;

    &:disabled {
      cursor: auto;
    }
  }
  .form-check-input:disabled ~ label {
    cursor: auto;
  }
  label {
    margin-top: 6px;
    cursor: pointer;
    user-select: none;
  }

  &.radio-success {
    .form-check-input {
      &:checked {
        background-color: $success;
        border-color: $success;
      }
    }
  }
  &.radio-warning {
    .form-check-input {
      &:checked {
        background-color: $warning;
        border-color: $warning;
      }
    }
  }
  &.radio-danger {
    .form-check-input {
      &:checked {
        background-color: $danger;
        border-color: $danger;
      }
    }
  }
}

.button-group {
  @media #{$xs} {
    .main-btn {
      width: 100%;
    }
  }
}

.buy-sell-form {
  .input-group {
    display: flex;

    input {
      width: 60%;
      background: transparent;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      padding: 8px 16px;
      font-size: 14px;
      color: $gray;

      &:focus {
        border-color: $primary;
      }
    }

    .select-style-1 {
      width: 40%;

      .select-position {
        &::after {
          width: 8px;
          height: 8px;
        }
      }
    }

    select {
      border: 1px solid #e2e8f0;
      border-radius: 0px 4px 4px 0px;
      padding: 8px 16px;
      padding-right: 24px;
      font-size: 14px;
      color: $gray;
    }
  }

  .buy-sell-btn {
    .main-btn {
      display: block;
      width: 100%;
      font-weight: 500;

      &:hover {
        box-shadow: $shadow-one;
      }

      &.success-btn {
        background: #08c18d;
      }

      &.danger-btn {
        background: #eb5757;
      }
    }
  }

  .field-group-2 {
    label {
      font-size: 12px;
    }

    .input-group {
      input {
        font-size: 12px;
        width: 70%;
      }

      span {
        font-size: 12px;
        padding: 8px 16px;
        width: 30%;
        background: #e2e8f0;
        text-align: center;
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #e2e8f0;
      }
    }
  }

  .input-group-2 {
    label {
      font-size: 12px;
      color: $gray;
      margin-bottom: 8px;
      display: block;
    }

    .select-position {
      &::after {
        width: 8px;
        height: 8px;
      }
    }

    select {
      padding: 8px 12px;
      font-size: 12px;
      color: $gray;
      border: 1px solid #e2e8f0;
      border-radius: 4px;
      width: 100%;
    }
  }
}

/* ========== Theme Change Css =========== */
.darkTheme {
  .input-style-1,
  .input-style-2,
  .input-style-3 {
    label {
      color: $white;
    }

    input,
    textarea {
      background: rgba($dark-2, 0.2);
      border-color: $dark-2;
      color: rgba($white, 0.7);

      &:focus {
        background: $dark;
        border-color: $light;
      }

      &::placeholder {
        color: rgba($white, 0.5);
      }

      &.light-bg {
        &[type="date"],
        &[type="time"] {
          background: rgba($dark-2, 0.2);
          border-color: $dark-2;
          color: rgba($white, 0.7);
        }
      }
      &.light-bg {
        &[type="date"]:focus,
        &[type="time"]:focus {
          background: $dark;
          border-color: $light;
        }
      }
    }
  }

  .select-style-1,
  .select-style-2,
  .select-style-3 {
    label {
      color: $white;
    }

    select {
      border-color: $dark-2;
      color: $white-50;

      &:focus {
        background: $dark;
        border-color: $light;
      }

      &.light-bg {
        background: rgba($dark-2, 0.2);
        border-color: $dark-2;
        color: rgba($white, 0.7);
      }

      &.light-bg:focus {
        background: $dark;
        border-color: $light;
      }
    }
  }

  .select-style-3 {
    select {
      color: $white;
    }
  }

  .toggle-switch {
    label {
      color: $white;
    }
  }

  .checkbox-style {
    .form-check-input {
      border-color: rgba($light, 0.5);
      background-color: transparent;

      &:checked {
        background-color: $primary;
        border-color: $primary;
      }
    }
    label {
      color: $white;
    }

    &.checkbox-success {
      .form-check-input {
        &:checked {
          background-color: $success;
          border-color: $success;
        }
      }
    }
    &.checkbox-warning {
      .form-check-input {
        &:checked {
          background-color: $warning;
          border-color: $warning;
        }
      }
    }
    &.checkbox-danger {
      .form-check-input {
        &:checked {
          background-color: $danger;
          border-color: $danger;
        }
      }
    }
  }

  //* ===== radio style  */
  .radio-style {
    .form-check-input {
      background-color: transparent;
      border-color: rgba($light, 0.5);

      &:checked {
        background-color: $primary;
        border-color: $primary;
      }
    }
    label {
      color: $white;
    }

    &.radio-success {
      .form-check-input {
        &:checked {
          background-color: $success;
          border-color: $success;
        }
      }
    }
    &.radio-warning {
      .form-check-input {
        &:checked {
          background-color: $warning;
          border-color: $warning;
        }
      }
    }
    &.radio-danger {
      .form-check-input {
        &:checked {
          background-color: $danger;
          border-color: $danger;
        }
      }
    }
  }

  .field-group-2 {
    .input-group {
      input {
        border-color: $light;
        color: rgba($white, 0.7);
      }

      span {
        background: #e2e8f0;
        color: $dark;
        border: 1px solid #e2e8f0;
      }
    }
  }

  .input-group-2 {
    select {
      border-color: $light;
    }
  }

  .field-group {
    .input-group {
      input {
        color: rgba($white, 0.7);
      }
    }
    .select-style-1 {
      select {
        border-color: $light;
      }
    }
  }
}
