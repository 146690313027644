/* ============== clients css ============ */

.client-profile-wrapper {
  background: $white;
  border-radius: 4px;

  .client-cover {
    border-radius: 10px 10px 0px 0px;
    overflow: hidden;
    position: relative;
    height: 160px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .update-image {
      position: absolute;
      right: 30px;
      bottom: 20px;
      background: $white-50;
      border-radius: 30px;
      padding: 1px 15px;
      display: inline-block;
      width: auto;
      color: $dark;
      z-index: 9999;
      cursor: pointer;

      &:hover {
        opacity: 0.9;
      }

      @media #{$xs} {
        right: 50%;
        bottom: 50%;
        @include transform(translate(50%, 50%));
        white-space: nowrap;
      }

      input {
        opacity: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        z-index: 99999;
        cursor: pointer;
      }

      label {
        z-index: 99;
        cursor: pointer;

        i {
          margin-right: 10px;
        }
      }
    }
  }

  .client-profile-photo {
    position: relative;
    margin-top: -80px;

    @media #{$xs} {
      margin-top: -50px;
    }

    .image {
      max-width: 170px;
      width: 100%;
      height: 170px;
      border-radius: 50%;
      position: relative;
      margin: 0 auto;
      border: 6px solid $white;

      @media #{$xs} {
        max-width: 120px;
        height: 120px;
      }

      img {
        width: 100%;
        border-radius: 50%;
      }

      .update-image {
        position: absolute;
        right: 0px;
        bottom: 0px;
        border: 2px solid $white;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        background: $light;
        color: $dark;
        @include flex-center;
        z-index: 99;
        cursor: pointer;

        &:hover {
          opacity: 0.9;
        }

        input {
          opacity: 0;
          position: absolute;
          right: 0;
          top: 0;
          width: 100%;
          z-index: 99;
          cursor: pointer;
        }

        label {
          z-index: 99;
          cursor: pointer;
        }
      }
    }
  }

  .client-info {
    padding: 30px;

    .socials {
      display: flex;
      align-items: center;

      li {
        a {
          width: 40px;
          height: 40px;
          @include flex-center;
          background: rgba($light, 0.5);
          border: 1px solid $light-2;
          border-radius: 50%;
          color: $gray;
          font-size: 16px;
          margin-right: 20px;

          &:hover {
            color: $white;
            background: $primary;
            border-color: $primary;
          }
        }
      }
    }
  }
}

.clients-table-card {
  .dropdown-toggle {
    border: none;
    background: none;

    &::after {
      display: none;
    }
  }
}

/* ============ Theme Change Css =============== */
.darkTheme {
  .client-profile-wrapper {
    background: $dark-4;

    .client-info {
      .socials {
        li {
          a {
            background: rgba($dark, 0.5);
            border: 1px solid $dark-2;
            color: $white;

            &:hover {
              color: $white;
              background: $primary;
              border-color: $primary;
            }
          }
        }
      }
    }
  }
}
