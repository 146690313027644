/* ============ Projects Css =========== */
.project-card {
  .card-body {
    padding: 15px 0px;

    .chart-wrapper {
      display: flex;
      align-items: center;

      .chart {
        max-width: 55px;
        width: 100%;
        height: 55px;
        position: relative;
        margin-bottom: 25px;
        margin-right: 20px;

        span {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ldBar-chart {
        position: relative;

        .baseline {
          stroke: inherit;
          stroke-width: 8px;
          opacity: 0.1;
        }
        .mainline {
          stroke: inherit;
          stroke-width: 8px;
        }

        .ldBar-label {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          color: inherit;
        }
      }

      .chart-body {
        width: 250px;
        margin: auto;

        .ldBar-label {
          font-size: 40px;
          font-weight: 700;
          text-align: center;
          flex-direction: column;

          &::after {
            content: "Task completed";
            font-size: 14px;
            display: block;
            width: 100%;
            font-weight: 500;
            color: $gray;
          }
        }
      }
    }
  }

  .card-footer {
    .project-image {
      display: flex;
      align-items: center;
      margin-top: 15px;

      ul {
        display: flex;
        align-items: center;

        li {
          width: 28px;
          height: 28px;
          border: 2px solid $white;
          overflow: hidden;
          margin-left: -10px;
          border-radius: 50%;

          &:first-child {
            margin-left: 0px;
          }

          img {
            width: 100%;
          }
        }
      }

      a {
        margin-left: 10px;
        color: $gray;
        font-size: 14px;
      }
    }
  }
}

.activity-wrapper {
  ul {
    li {
      display: flex;
      padding-bottom: 30px;
      position: relative;
      z-index: 1;

      &:last-child {
        padding-bottom: 0px;
      }

      &::after {
        content: "";
        width: 2px;
        height: 100%;
        border-left: 1px dashed $black-10;
        position: absolute;
        left: 25px;
        top: 0;
        z-index: -1;
      }

      @media (max-width: 375px) {
        flex-direction: column;

        &::after {
          display: none;
        }

        .icon {
          margin-bottom: 10px;
        }
        .content {
          .title {
            flex-wrap: wrap;
          }
        }
      }

      &.primary {
        .icon {
          border-color: rgba($primary, 0.1);

          i {
            background: rgba($primary, 0.1);
            color: $primary;
          }
        }
      }
      &.danger {
        .icon {
          border-color: rgba($danger, 0.1);

          i {
            background: rgba($danger, 0.1);
            color: $danger;
          }
        }
      }
      &.success {
        .icon {
          border-color: rgba($success, 0.1);

          i {
            background: rgba($success, 0.1);
            color: $success;
          }
        }
      }
      &.orange {
        .icon {
          border-color: rgba($orange, 0.1);

          i {
            background: rgba($orange, 0.1);
            color: $orange;
          }
        }
      }

      .icon {
        max-width: 50px;
        width: 100%;
        height: 50px;
        position: relative;
        border-radius: 50%;
        border: 2px solid;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 15px;
        background: $white;
        font-size: 20px;

        i {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .content {
        p {
          a {
            color: $gray;
          }
        }
      }
    }
  }
}

.stroke-primary {
  stroke: $primary;
}
.stroke-success {
  stroke: $success;
}
.stroke-orange {
  stroke: $orange;
}
.stroke-warning {
  stroke: $warning;
}
.stroke-info {
  stroke: $info;
}
.stroke-danger {
  stroke: $danger;
}

// ==== darkTheme

.darkTheme {
  .activity-wrapper {
    ul {
      li {
        &::after {
          border-color: $white-10;
        }
      }
    }
  }
}
