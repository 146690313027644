/* ============ task css ============= */
.task-table {
  &.task-completed {
    text-decoration: line-through;
  }

  .table {
    tr {
      @include transition(0.3s);

      &:hover {
        box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
        -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
      }
      &:last-child > * {
        padding-bottom: 15px;
      }
    }
    td {
      padding: 10px;

      &.min-width {
        min-width: 150px;
      }
      &.name-title {
        min-width: 200px;
      }

      &:first-child > * {
        padding-left: 30px;
      }
      &:last-child > * {
        padding-right: 30px;
      }

      .action {
        .delete-btn {
          &:hover {
            color: $danger !important;
          }
        }

        .link-btn {
          &:hover {
            color: $primary !important;
          }
        }

        .more-btn {
          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
