/* ========== Option Css ============== */

.option-btn {
  position: fixed;
  bottom: 50px;
  right: 50px;
  width: 55px;
  height: 55px;
  background: $primary;
  font-size: 24px;
  color: $white;
  border: none;
  @include box-shadow(0px 0px 4px rgba(0, 0, 0, 0.1));
  z-index: 999;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    animation: rotate 2s infinite linear;
  }
}

.rightSidebar {
  .option-btn {
    right: auto;
    left: 50px;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.option-overlay {
  width: 100%;
  height: 100vh;
  background: rgba($black, 0.2);
  right: 0;
  top: 0;
  position: fixed;
  @include transform(translateX(100%));
  z-index: 9999;

  &.show {
    @include transform(translateX(0));
  }
}
.option-header {
  border-bottom: 1px solid #e2e2e2;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;

  h5 {
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 4px;
  }

  p {
    font-size: 12px;
    line-height: 1.2;
  }

  .option-btn-close {
    background: none;
    border: 0px;
    margin-left: 5px;

    i {
      font-weight: 700;
      font-size: 14px;
    }
  }
}

.option-box {
  position: fixed;
  top: 0;
  right: 0;
  width: 320px;
  height: 100vh;
  overflow-y: auto;
  background: $white;
  @include box-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  @include transition(0.3s);
  @include transform(translateX(100%));
  display: flex;
  flex-direction: column;
  padding: 20px;
  z-index: 99999;

  &.show {
    @include transform(translateX(0));
  }

  li {
    margin-right: 15px;

    &:last-child {
      margin-right: 0px;
    }

    button {
      display: block;
      background: none;
      border: none;
      padding: 8px 8px 8px 20px;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 12px;
        height: 12px;
        border: 2px solid $primary;
        background: transparent;
        left: 0;
        top: calc(50% - 6px);
        border-radius: 50%;
      }

      &.active {
        color: $primary;

        &::before {
          background: $primary;
        }
      }
    }
  }

  .promo-box {
    max-width: 100%;

    h3 {
      font-size: 16px;
    }

    p {
      font-size: 13px;
    }
  }
}
